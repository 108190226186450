var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_vm._m(0),_c('div',{staticClass:"contain"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"content"},[_c('div',{staticClass:"carousel"},[_c('div',{staticStyle:{"position":"relative","margin":"0 auto","top":"0px","left":"0px","width":"800px","height":"710px","overflow":"hidden","visibility":"hidden","background-color":"#ffffff"},attrs:{"id":"jssor_1"}},[_vm._m(4),_c('div',{staticStyle:{"cursor":"default","position":"relative","top":"0px","left":"0px","width":"800px","height":"538px","overflow":"hidden"},attrs:{"data-u":"slides"}},_vm._l((_vm.imgList),function(item,index){return _c('div',{key:index,staticClass:"img-g",staticStyle:{"display":"none"},attrs:{"data-p":"144.50"}},[_c('div',{attrs:{"data-u":"image"}},[_c('img',{attrs:{"src":item,"width":"100%","height":"100%"}})]),_c('div',{attrs:{"data-u":"thumb"}},[_c('img',{attrs:{"src":item,"width":"100%","height":"100%"}})])])}),0),_vm._m(5),_c('span',{staticClass:"jssora05l",staticStyle:{"top":"260px","left":"8px","width":"40px","height":"40px"},attrs:{"data-u":"arrowleft"}}),_c('span',{staticClass:"jssora05r",staticStyle:{"top":"260px","right":"8px","width":"40px","height":"40px"},attrs:{"data-u":"arrowright"}})])])]),_vm._m(6),_c('el-row',{staticClass:"content",attrs:{"gutter":10}},_vm._l((_vm.newsList),function(item,index){return _c('el-col',{key:index,attrs:{"span":12}},[_c('div',{on:{"click":function($event){return _vm.openClick(item)}}},[_c('el-row',{staticClass:"news"},[_c('el-col',{attrs:{"span":7}},[_c('img',{staticStyle:{"display":"block"},attrs:{"src":item.img,"width":"100%","height":"100%"}})]),_c('el-col',{staticClass:"news-item",attrs:{"span":17}},[_c('p',{staticClass:"tit"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"detail"},[_vm._v(_vm._s(item.detail))]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(item.time))])])],1)],1)])}),1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":"https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/banner.png","width":"100%"}}),_c('div',{staticClass:"content"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-groups"},[_c('p',{staticClass:"title"},[_vm._v("核心高管")]),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h3',{staticStyle:{"text-indent":"0em"}},[_vm._v("王森华 CEO 创始人")]),_c('p',{staticStyle:{"text-indent":"0em"}},[_vm._v("北京航空航天大学博士，专注于互联网大数据。资深互联网从业者，多年的国内著名企业管理经验，一直在TOP1的互联网企业担任高级管理者。在商业模式创新、互联网平台架构设计、 产业互联网创新、互联网公司运营方面有资深的专业经验。曾任职于民生银行、去哪儿网、 滴滴出行、运满满 。")]),_c('h3',{staticStyle:{"text-indent":"0em"}},[_vm._v("李梓慧 CMO 首席营销官")]),_c('p',{staticStyle:{"text-indent":"0em"}},[_vm._v("南京大学本科 ，18年销售管理经验，历任富士康商务部经理、苏州奥迪大客户总监（2013年全国奥迪销售冠军）。2015年加入智通三千，联合创始人、董事、任全国招商总经理（副总裁），三年多的时间招商业绩三个多亿，布局线下900多个分公司，年交易额突破50亿。销售、管理领域有丰富的经验。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-groups"},[_c('p',{staticClass:"title"},[_vm._v("团队照片")]),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"absolute","top":"0px","left":"0px"},attrs:{"data-u":"loading"}},[_c('div',{staticStyle:{"filter":"alpha(opacity=70)","opacity":"0.7","position":"absolute","display":"block","top":"0px","left":"0px","width":"100%","height":"100%"}}),_c('div',{staticStyle:{"position":"absolute","display":"block","background":"url('https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/loading.gif') no-repeat center center","top":"0px","left":"0px","width":"100%","height":"100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jssort01",staticStyle:{"position":"absolute","left":"0px","bottom":"0px","width":"800px","height":"175px"},attrs:{"data-u":"thumbnavigator","data-autocenter":"1"}},[_c('div',{staticStyle:{"cursor":"default"},attrs:{"data-u":"slides"}},[_c('div',{staticClass:"p",attrs:{"data-u":"prototype"}},[_c('div',{staticClass:"w"},[_c('div',{staticClass:"t",attrs:{"data-u":"thumbnailtemplate"}})]),_c('div',{staticClass:"c"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-groups"},[_c('p',{staticClass:"title"},[_vm._v("企业新闻")]),_c('div',{staticClass:"line"})])
}]

export { render, staticRenderFns }