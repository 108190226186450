<template>
  <div class="about">
    <div class="top">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/about/banner.jpg"/>
      <div class="content">
      </div>
    </div>
    <div class="contain">
      <div class="main">
        <div class="ban-4">
          <p class="title">加入我们</p>
          <div class="line"></div>
          <el-row class="content" :gutter="20">
            <el-col :span="12" class="join">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/about/ab-4.jpg" width="100%"/>
              <div class="top-content">
                <el-row>
                  <el-col :span="16">
                    <p class="t-1">司机招募</p>
                    <p class="t-2">咱板车司机的自己找货平台，海量货源、永不收取会员费、降低欺诈风险、提升司机收益、打造智能、便利、安全有保障的一站式司机运输服务平台。</p>
                  </el-col>
                  <el-col :span="8">
                    <img class='image0'
                         src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/footer/car.png"/>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="12" class="join">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/about/ab-2.jpg" width="100%"/>
              <div class="top-content">
                <el-row>
                  <el-col :span="16">
                    <p class="t-1">运力调度</p>
                    <p class="t-2">为承运商打造专业、智能、高效、共赢的运力管理平台，实现在线调车、在线结算、在线监控、在线购买保险等，一站式智能调度服务。</p>
                  </el-col>
                  <el-col :span="8">
                    <img class='image0'
                         src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/footer/owner.png"/>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="ban-5">
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/about/ab-3.jpg" width="100%"/>
          <div class="content">
            <div class="t-1">物流承运商加盟</div>
            <div class="t-2">我们会尽快给您回复，期待您的加入</div>
            <el-row style='padding:0' :gutter="20" class="form">
              <el-col style='padding:0 0.1rem' :span="8">
                <el-input v-model="form.name" placeholder="姓名"/>
              </el-col>
              <el-col style='padding:0 0.1rem' :span="8">
                <el-input v-model="form.phone" placeholder="手机号" maxlength="11"/>
              </el-col>
              <el-col style='padding:0 0.1rem' :span="8">
                <el-input v-model="form.company" placeholder="企业名称"/>
              </el-col>
            </el-row>
            <div class="sub">
              <el-button type="primary" round @click="sub">信息提交</el-button>
            </div>
          </div>
        </div>
        <div class="ban-6">
          <p class="title">联系我们</p>
          <div class="line"></div>
          <p class="t-2">如果您有什么好的想法和意见，欢迎随时联系我们</p>
          <div id="container"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "about",
  data() {
    return {
      url: 'https://www.zhiyuntongkj.com/prod/user/website/partnerInput',
      form: {
        name: '',
        phone: '',
        company: ''
      }
    }
  },
  mounted() {
    let marker = '';
    let map = new AMap.Map("container", {
      resizeEnable: true,
      center: [116.367198, 40.099054],
      zoom: 17
    });
    marker = new AMap.Marker({
      icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
      position: [116.367198, 40.099054],
      offset: new AMap.Pixel(-13, -30)
    });
    marker.setMap(map);
  },
  methods: {
    sub() {
      axios.post(this.url, this.form).then(res => {
        console.log(res);
        if (res.data.code === 0) {
          this.$message.success('提交完成');
        } else {
          this.$message.error(res.data.message);
        }
      });
    }
  }
}
</script>

<style lang="less">
.el-icon-error {
  font-size: 14px !important;
}

.about {
  margin-top: 0.90rem;

  i {
    font-size: 12px;
  }

  .top {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .content {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;

      .t-1 {
        font-size: 0.26rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.37rem;
        text-indent: 0.015em;
      }

      .t-2 {
        font-size: 0.18rem;
        font-weight: 400;
        text-indent: 0.015em;
        color: #FFFFFF;
        line-height: 0.25rem;
        margin-top: 0.11rem;
      }
    }
  }

  .contain {
    .main {
      padding-top: 1.06rem;

      .title {
        text-align: left;
        font-size: 0.30rem;
        font-weight: bold;
        color: #222222;
        line-height: 0.42rem;
        margin: 0;
      }

      .line {
        width: 0.34rem;
        height: 0.03rem;
        background: #0274F6;
        margin-top: 0.07rem;
        margin-bottom: 0.20rem;
      }

      .left {
        text-align: left;

        .introduce {
          font-size: 0.16rem;
          font-weight: 400;
          color: #222;
          line-height: 0.3rem;
          margin-bottom: 0.15rem;
        }
      }

      .ban-2 {
        text-align: left;
        margin-top: 0.35rem;

        .content {
          font-size: 0.16rem;
          font-weight: 400;
          color: #222;
          line-height: 0.3rem;
        }
      }

      .ban-3 {
        text-align: left;
        margin-top: 0.67rem;

        .content {
          font-size: 0.16rem;
          font-weight: 400;
          color: #222;
          line-height: 0.3rem;
        }
      }

      .ban-4 {
        margin-top: 0.83rem;

        .title {
          text-align: center !important;
        }

        .line {
          margin: 0.07rem auto;
        }

        .content {
          margin-top: 0.35rem;

          .join {
            position: relative;

            .top-content {
              position: absolute;
              top: 20%;
              left: 9%;
              right: 8%;
              text-align: left;

              .image0 {
                width: 1.48rem;
                height: 1.50rem;
              }

              .t-1 {
                font-size: 0.26rem;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 0.37rem;
                margin-top: -0.1rem;
              }

              .t-2 {
                font-size: 0.16rem;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 0.22rem;
                width: 2.90rem;
                margin-top: 0.20rem;
                line-height: 0.3rem;
              }
            }
          }
        }
      }

      .ban-5 {
        margin-top: 0.20rem;
        position: relative;

        .content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding-top: 0rem;

          .t-1 {
            position: relative;
            float: left;
            font-size: 0.24rem;
            font-weight: bold;
            width: 100%;
            height: 0.33rem;
            color: #FFFFFF;
            line-height: 0.33rem;
            text-align: center;
            margin-top: 0.25rem;
          }

          .t-2 {
            position: relative;
            float: left;
            width: 100%;
            height: 0.2rem;
            margin-top: 0.10rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.20rem;
            text-align: center;
          }

          .form {
            position: absolute;
            width: 100%;
            top: 1.1rem;
            padding: 0rem 0.71rem !important;
            transform: translateX(-50%);
            margin-left: 50% !important;

            .el-col-8 {
              .el-input {
                float: left;


                .el-input__inner {
                  background: rgb(15 21 64 / 30%);
                  border-radius: 0.02rem;
                  height: 0.50rem;
                  color: #fff;
                  border: 1px solid rgba(15, 21, 64, 0.3);
                  padding: 0rem 0.20rem;
                  color: #ffffff;
                  font-size: 0.16rem;

                  &:hover, &:active, &:before, &:after {
                    background: rgb(15 21 64 / 30%);
                    border-radius: 2px;
                    border: 1px solid rgba(15, 21, 64, 0.3);
                    color: #ffffff;
                  }
                }
              }
            }
          }

          .sub {
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            top: 2rem;

            .el-button {
              width: 1.80rem;
              height: 0.50rem;
              padding: 0;
              line-height: 0.50rem;
              background: #0274F6;
              border-radius: 0.25rem;
              font-size: 0.18rem;
              font-weight: 500;
              color: #FFFFFF;
              border: 0;
              float: left;

            }
          }
        }
      }

      .ban-6 {
        margin-top: 0.80rem;

        .title {
          text-align: center !important;
        }

        .line {
          margin: 0.07rem auto;
        }

        .t-2 {
          font-size: 0.16rem;
          font-weight: 400;
          color: #222222;
          text-align: center;
          line-height: 0.22rem;
          margin-bottom: 0.22rem;
        }

        #container {
          width: 100%;
          margin-bottom: 77px;
          height: 272px;

          .amap-icon {
            overflow: visible !important;
          }

          .amap-icon img,
          .amap-marker-content img {
            width: 25px;
            height: 34px;
          }

          .marker {
            position: absolute;
            top: -20px;
            right: -118px;
            color: #fff;
            padding: 4px 10px;
            box-shadow: 1px 1px 1px rgba(10, 10, 10, .2);
            white-space: nowrap;
            font-size: 12px;
            font-family: "";
            background-color: #25A5F7;
            border-radius: 3px;
          }
        }
      }
    }
  }
}

</style>
