<template>
  <div class="profession">
    <img class='homeimage' src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/image1.jpg'/>
    <div class='content_item'>
      <div class='content_item_title'>技术专利</div>
      <div class='content_item_line'></div>
      <div style='font-weight:bold;color:#222' class='content_item_info'>轴线车转弯半径计算程序</div>
      <div style='color:#222' class='content_item_desc'>本系统应用于大型车辆运输，操作简单，只需输入车型的系数便可计算出结果。
        测算出需要的转弯半径，从而避免在通过弯道时因车货过长转弯半径不够而导致运输受阻。本系统操作简便，只需输入车型的系数便可计算出结果
      </div>
      <div style='margin-top:0.60rem;font-weight:bold;color:#222' class='content_item_info'>牵引力车爬坡度及牵引力计算程序</div>
      <div style='color:#222' class='content_item_desc'>
        本系统应用于大型车辆运输，根据车型的系数，对车辆装载货物所需的最大牵引力及爬坡度进行计算，从而避免因货物超载导致运输过程中引发事故。本系统操作简便，只需输入车型的系数便可计算出结果
      </div>
      <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/t1.png'
           class='content_item_image'/>
    </div>
    <div style='margin-top:1.80rem' class='content_item'>
      <div style='float:right;text-align:right;' class='content_item_title'>专利证书</div>
      <div style='float:right;margin-right:0rem;' class='content_item_line'></div>
      <div style='float:right;text-align:right;width:100%;margin-top:0.20rem;font-weight:bold;'
           class='content_item_info'>轴线车转弯半径计算程序
      </div>
      <img class='content_item_image0'
           src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/item_image0.jpg'/>
      <img class='content_item_image1'
           src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/item_image1.png'/>
    </div>
    <div class='content_item0'>
      <div class='content_item1'>
        <div @click='changeItem(0)' style='cursor:pointer;color:#fff;margin-top:0.30rem;' :class='imageTitle'>企业资质</div>
        <div v-if='image0=="opacity:1;left:5.80rem;transform:scale(1)"' style='background-color:#fff'
             class='content_item_line'></div>
        <div class='content_item0_image1_div'>
          <img @click='changeItem(0)' class='content_item0_image1'
               src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/o1.png'/>
        </div>
        <div @click='changeItem(1)' style='color:#fff;margin-top:0.50rem;cursor:pointer;' :class='imageTitle0'>专业资质
        </div>
        <div v-if='image0=="opacity:0;left:1.50rem;transform:scale(0.2)"' style='background-color:#fff'
             class='content_item_line'></div>
        <div class='content_item0_image1_div'>
          <img @click='changeItem(1)' class='content_item0_image1'
               src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/o2.png'/>
        </div>
        <img :style='image0' class='content_item_right_image'
             src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/o6.png'/>
        <img :style='image1' class='content_item_right_image'
             src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/o3.png'/>
      </div>
    </div>
    <div class='hometitlediv'>
      <div class='hometitle'>合作案例</div>
      <div class='hometitleline'></div>
      <div class='hometitledesc'>
        <div class="carousel contain">
          <div id="jssor_1"
               style="position: relative; margin: 0 auto; top: 0px; left: 0px; width: 800px; height: 710px; overflow: hidden; visibility: hidden; background-color: #ffffff;">
            <!-- Loading Screen -->
            <div data-u="loading" style="position: absolute; top: 0px; left: 0px;">
              <div
                  style="filter: alpha(opacity=70); opacity: 0.7; position: absolute; display: block; top: 0px; left: 0px; width: 100%; height: 100%;"></div>
              <div
                  style="position:absolute;display:block;background:url('https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/loading.gif') no-repeat center center;top:0px;left:0px;width:100%;height:100%;"></div>
            </div>
            <div data-u="slides"
                 style="cursor: default; position: relative; top: 0px; left: 0px; width: 800px; height: 538px; overflow: hidden;">
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image" @click="showVideo(0)">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/10.jpg" width="100%" height="100%"/>
                  <p class="title">JSTV_JSCS报道火车车厢运输</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/10.jpg" width="100%" height="100%"/>
                  <p class="title">JSTV_JSCS报道火车车厢运输</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image" @click="showVideo(1)">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/11.jpg" width="100%" height="100%"/>
                  <p class="title">南京百吨“庞然大物”通行难 一路拆信号灯</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/11.jpg" width="100%" height="100%"/>
                  <p class="title">南京百吨“庞然大物”通行难 一路拆信号灯</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image" @click="showVideo(2)">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/12.jpg" width="100%" height="100%"/>
                  <p class="title">国产最大氨合成塔运输</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/12.jpg" width="100%" height="100%"/>
                  <p class="title">国产最大氨合成塔运输</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/1.jpg" width="100%" height="100%"/>
                  <p class="title">西门子出口变压器</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/1.jpg" width="100%" height="100%"/>
                  <p class="title">西门子出口变压器</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/2.jpg" width="100%" height="100%"/>
                  <p class="title">PC单轨梁运输</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/2.jpg" width="100%" height="100%"/>
                  <p class="title">PC单轨梁运输</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/3.jpg" width="100%" height="100%"/>
                  <p class="title">长途运输最重件525t</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/3.jpg" width="100%" height="100%"/>
                  <p class="title">长途运输最重件525t</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/4.jpg" width="100%" height="100%"/>
                  <p class="title">长途运输最大直径5.3m</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/4.jpg" width="100%" height="100%"/>
                  <p class="title">长途运输最大直径5.3m</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/5.jpg" width="100%" height="100%"/>
                  <p class="title">顺风大运专车</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/5.jpg" width="100%" height="100%"/>
                  <p class="title">顺风大运专车</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/6.jpg" width="100%" height="100%"/>
                  <p class="title">长途运输最长哈氏合金塔器71m</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/6.jpg" width="100%" height="100%"/>
                  <p class="title">长途运输最长哈氏合金塔器71m</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/7.jpg" width="100%" height="100%"/>
                  <p class="title">隧道管片</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/7.jpg" width="100%" height="100%"/>
                  <p class="title">隧道管片</p>
                </div>
              </div>
              <div data-p="144.50" style="display: none;" class="img-g">
                <div data-u="image">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/9.jpg" width="100%" height="100%"/>
                  <p class="title">二航局运梁照片</p>
                </div>
                <div data-u="thumb">
                  <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/9.jpg" width="100%" height="100%"/>
                  <p class="title">二航局运梁照片</p>
                </div>
              </div>
            </div>
            <!-- Thumbnail Navigator -->
            <div data-u="thumbnavigator" class="jssort01"
                 style="position:absolute;left:0px;bottom:0px;width:800px;height:175px;" data-autocenter="1">
              <!-- Thumbnail Item Skin Begin -->
              <div data-u="slides" style="cursor: default;">
                <div data-u="prototype" class="p">
                  <div class="w">
                    <div data-u="thumbnailtemplate" class="t"></div>
                  </div>
                  <div class="c"></div>
                </div>
              </div>
              <!-- Thumbnail Item Skin End -->
            </div>
            <!-- Arrow Navigator -->
            <span data-u="arrowleft" class="jssora05l" style="top:260px;left:8px;width:40px;height:40px;"></span>
            <span data-u="arrowright" class="jssora05r" style="top:260px;right:8px;width:40px;height:40px;"></span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="视频播放" :visible.sync="videoDialogVisible" width="40%" center>
      <video :src="videoUrl" :controls="videoOptions.controls" class="video-js vjs-big-play-centered vjs-fluid"
             webkit-playsinline="true" playsinline="true" x-webkit-airplay="allow" x5-playsinline style="width: 100%;"
             autoplay="autoplay" ref="video"/>
    </el-dialog>
  </div>
</template>

<script src="../utils/jssor.slider.mini.js"></script>
<script>
import '../utils/jssor.slider.mini.js'

export default {
  name: "profession",
  data() {
    return {
      videoDialogVisible: false,
      videoOptions: {controls: true},
      videoList: ['https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/n1.mp4', 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/n2.mp4', 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/n3.mp4'],
      videoUrl: '',
      videoa: false,
      videob: false,
      image0: 'opacity:1;left:5.80rem;transform:scale(1)',
      image1: 'opacity:0;left:1.50rem;transform:scale(0.2)',
      imageTitle: 'content_item_title',
      imageTitle0: 'content_item_info',
    }
  },
  mounted() {

    jQuery(document).ready(function ($) {

      var jssor_1_SlideshowTransitions = [
        {
          $Duration: 1200,
          x: 0.3,
          $During: {$Left: [0.3, 0.7]},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: -0.3,
          $SlideOut: true,
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: -0.3,
          $During: {$Left: [0.3, 0.7]},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $SlideOut: true,
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $During: {$Top: [0.3, 0.7]},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: -0.3,
          $SlideOut: true,
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: -0.3,
          $During: {$Top: [0.3, 0.7]},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $SlideOut: true,
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $Cols: 2,
          $During: {$Left: [0.3, 0.7]},
          $ChessMode: {$Column: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $Cols: 2,
          $SlideOut: true,
          $ChessMode: {$Column: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $Rows: 2,
          $During: {$Top: [0.3, 0.7]},
          $ChessMode: {$Row: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $Rows: 2,
          $SlideOut: true,
          $ChessMode: {$Row: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $Cols: 2,
          $During: {$Top: [0.3, 0.7]},
          $ChessMode: {$Column: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: -0.3,
          $Cols: 2,
          $SlideOut: true,
          $ChessMode: {$Column: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $Rows: 2,
          $During: {$Left: [0.3, 0.7]},
          $ChessMode: {$Row: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: -0.3,
          $Rows: 2,
          $SlideOut: true,
          $ChessMode: {$Row: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          y: 0.3,
          $Cols: 2,
          $Rows: 2,
          $During: {$Left: [0.3, 0.7], $Top: [0.3, 0.7]},
          $ChessMode: {$Column: 3, $Row: 12},
          $Easing: {$Left: $Jease$.$InCubic, $Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          y: 0.3,
          $Cols: 2,
          $Rows: 2,
          $During: {$Left: [0.3, 0.7], $Top: [0.3, 0.7]},
          $SlideOut: true,
          $ChessMode: {$Column: 3, $Row: 12},
          $Easing: {$Left: $Jease$.$InCubic, $Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 3,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 3,
          $SlideOut: true,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$OutCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 12,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 12,
          $SlideOut: true,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$OutCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        }
      ];

      var jssor_1_options = {
        $AutoPlay: true,
        $SlideshowOptions: {
          $Class: $JssorSlideshowRunner$,
          $Transitions: jssor_1_SlideshowTransitions,
          $TransitionsOrder: 1
        },
        $ArrowNavigatorOptions: {
          $Class: $JssorArrowNavigator$
        },
        $ThumbnailNavigatorOptions: {
          $Class: $JssorThumbnailNavigator$,
          $Cols: 5,
          $SpacingX: 8,
          $SpacingY: 8,
          $Align: 360
        }
      };

      var jssor_1_slider = new $JssorSlider$("jssor_1", jssor_1_options);

      //responsive code begin
      //you can remove responsive code if you don't want the slider scales while window resizing
      function ScaleSlider() {
        var refSize = jssor_1_slider.$Elmt.parentNode.clientWidth;
        if (refSize) {
          refSize = Math.min(refSize, 900);
          jssor_1_slider.$ScaleWidth(refSize);
        } else {
          window.setTimeout(ScaleSlider, 30);
        }
      }

      ScaleSlider();
      $(window).bind("load", ScaleSlider);
      $(window).bind("resize", ScaleSlider);
      $(window).bind("orientationchange", ScaleSlider);
      //responsive code end
    });
  },
  methods: {
    showVideo(e) {
      this.videoUrl = this.videoList[e];
      this.videoDialogVisible = true;
    },
    changeItem(val) {
      if (val == 0) {
        this.image0 = 'opacity:1;left:5.80rem;transform:scale(1)'
        this.image1 = 'opacity:0;left:1.50rem;transform:scale(0.2)'
        this.imageTitle = 'content_item_title'
        this.imageTitle0 = 'content_item_info'
      }
      if (val == 1) {
        this.image0 = 'opacity:0;left:1.50rem;transform:scale(0.2)'
        this.image1 = 'opacity:1;left:5.80rem;transform:scale(1)'
        this.imageTitle = 'content_item_info'
        this.imageTitle0 = 'content_item_title'
      }
    },
    startVideoa() {
      this.videoa = true;
      this.videob = false;
      let b = document.getElementById("id2")
      b.pause()
      let a = document.getElementById("id1")
      a.play()
    },
    startVideob() {
      this.videob = true;
      this.videoa = false;
      let a = document.getElementById("id1")
      a.pause()
      let b = document.getElementById("id2")
      b.play()
    }
  }
}
</script>

<style lang="less">
.profession {
  .hometitledesc {
    padding-bottom: 20px;

    .carousel {
      .w {

        position: relative;

        .title {
          position: absolute;
          bottom: 1px;
          left: 0;
          right: 0;
          z-index: 999;
          color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .img-g {
        position: relative;

        .title {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          z-index: 999;
          color: #ffffff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .jssora05l, .jssora05r {
    display: block;
    position: absolute;
    /* size of arrow element */
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: url('https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/a17.png') no-repeat;
    overflow: hidden;
  }

  .jssora05l {
    background-position: -10px -40px;
  }

  .jssora05r {
    background-position: -70px -40px;
  }

  .jssora05l:hover {
    background-position: -130px -40px;
  }

  .jssora05r:hover {
    background-position: -190px -40px;
  }

  .jssora05l.jssora05ldn {
    background-position: -250px -40px;
  }

  .jssora05r.jssora05rdn {
    background-position: -310px -40px;
  }

  /* jssor slider thumbnail navigator skin 01 css */
  /*
  .jssort01 .p            (normal)
  .jssort01 .p:hover      (normal mouseover)
  .jssort01 .p.pav        (active)
  .jssort01 .p.pdn        (mousedown)
  */

  .jssort01 .p {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 140px;
  }

  .jssort01 .t {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .jssort01 .w {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .jssort01 .c {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 198px;
    height: 138px;
    border: #000 2px solid;
    box-sizing: content-box;
    background: url('https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/t01.png') -800px -800px no-repeat;
    _background: none;
  }

  .jssort01 .pav .c {
    top: 2px;
    _top: 0px;
    left: 2px;
    _left: 0px;
    width: 198px;
    height: 138px;
    border: #000 0px solid;
    _border: #fff 2px solid;
    background-position: 50% 50%;
  }

  .jssort01 .p:hover .c {
    top: 0px;
    left: 0px;
    width: 198px;
    height: 138px;
    border: #fff 1px solid;
    background-position: 50% 50%;
  }

  .jssort01 .p.pdn .c {
    background-position: 50% 50%;
    width: 198px;
    height: 138px;
    border: #000 2px solid;
  }

  * html .jssort01 .c, * html .jssort01 .pdn .c, * html .jssort01 .pav .c {
    /* ie quirks mode adjust */
    width: 198px;
    height: 138px;
  }

}

body {
  margin: 0;
  padding: 0;
}

.profession {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 0.90rem;
  overflow: hidden;
}

.profession .homeimage {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 3.00rem;
}

.profession .homeimage0 {
  position: relative;
  float: left;
  width: 62%;
  margin-left: 50%;
  transform: translateX(-50%);
  height: auto;
  margin-top: 0.50rem;
}

.profession .hometitlediv {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 0.80rem;
}

.profession .hometitle {
  position: relative;
  float: left;
  width: 100%;
  height: 0.56rem;
  font-size: 0.30rem;
  line-height: 0.56rem;
  font-weight: bold;
  text-align: center;
  color: #222;
}

.profession .hometitleline {
  position: relative;
  float: left;
  width: 0.34rem;
  height: 0.04rem;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #0274F6;
}

.profession .hometitledesc {
  position: relative;
  float: left;
  width: 100%;
  font-size: 0.16rem;
  line-height: 0.26rem;
  text-align: center;
  margin-top: 0.10rem;
}

.profession .homeitem {
  position: relative;
  float: left;
  width: 100%;
  max-width: 19.20rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0.30rem;
}

.profession .homeitem0 {
  position: relative;
  float: left;
  width: 33.3333%;
  height: 3.00rem;
  overflow: hidden;
}

.profession .homeitemimage {
  position: absolute;
  height: 0.40rem;
  left: 50%;
  transform: translateX(-50%);
  top: 1.00rem;
}

.profession .homeitemtitle {
  position: absolute;
  width: 100%;
  height: 0.32rem;
  line-height: 0.32rem;
  font-size: 0.24rem;
  color: #fff;
  text-align: center;
  top: 1.56rem;
}

.profession .homeitemdesc {
  position: absolute;
  width: 90%;
  height: auto;
  font-size: 0.14rem;
  line-height: 0.24rem;
  top: 1.93rem;
  color: #fff;
  left: 5%;
}

.profession .homeitemimagebg {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0rem;
  top: 0rem;
}

.profession .homeitem1 {
  position: relative;
  float: left;
  width: 12.20rem;
  height: auto;
  margin-left: 50%;
  padding-bottom: 1.50rem;
  transform: translateX(-50%);
  margin-top: 0.30rem;
}

.profession .homeitem0image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
}

.profession .homeitem0imagestart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.60rem;
  height: 0.60rem;
  z-index: 10;
}

.profession .imagediv {
  position: relative;
  float: left;
  width: 5.94rem;
  height: 4.05rem;
  margin-left: 0.10rem;
}

.profession .homeitem1image0div {
  position: relative;
  float: left;
  height: 1.53rem;
  margin-left: 0.10rem;
  margin-top: 0.10rem;
  width: 2.31rem;
}

.profession .homeitem1image0title {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  height: 0.30rem;
  line-height: 0.30rem;
  font-size: 0.14rem;
  text-align: center;
  color: #fff;
}

.profession .homeitem1image0 {
  position: absolute;
  top: 0rem;
  left: 0rem;
  height: 1.53rem;
  width: 2.31rem;
}

.profession .content_item {
  position: relative;
  float: left;
  width: 12.00rem;
  margin-left: 50%;
  margin-top: 1.42rem;
  text-align: left;
  transform: translateX(-50%);
}

.profession .content_item0 {
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 1.42rem;
  background-image: url('https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/item_image2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 6.00rem;
}

.profession .content_item1 {
  position: relative;
  float: left;
  width: 12.00rem;
  margin-left: 50%;
  padding-bottom: 1.96rem;
  margin-top: 1.42rem;
  text-align: left;
  padding-left: 0.50rem;
  transform: translateX(-50%);
}

.profession .content_item_title {
  position: relative;
  float: left;
  height: 0.42rem;
  width: 100%;
  color: #222;
  font-size: 0.40rem;
  line-height: 0.42rem;
  font-weight: bold;
}

.profession .content_item_line {
  position: relative;
  float: left;
  width: 0.34rem;
  height: 0.04rem;
  margin: 0.16rem 100% 0rem 0rem;
  background-color: #0274F6;
}

.profession .content_item_info {
  position: relative;
  float: left;
  height: 0.30rem;
  width: 60%;
  color: #222;
  font-size: 0.24rem;
  line-height: 0.30rem;
  margin-top: 0.39rem;
}

.profession .content_item_desc {
  position: relative;
  float: left;
  width: 56%;
  font-size: 0.16rem;
  line-height: 0.30rem;
  margin-top: 0.20rem;
  color: #222;
  font-weight: normal;
}

.profession .content_item_image {
  position: absolute;
  top: 0.20rem;
  right: 0rem;
  width: auto;
  height: 100%;
}

.profession .content_item_image0 {
  position: relative;
  float: right;
  height: 3.58rem;
  margin-top: 0.30rem;
}

.profession .content_item_image1 {
  position: absolute;
  height: 3.41rem;
  top: 0.95rem;
  left: 0rem;
}

.profession .content_item0_image1 {
  position: relative;
  float: left;
  height: 0.39rem;
  cursor: pointer;
}

.profession .content_item0_image1_div {
  position: relative;
  float: left;
  width: 100%;
  height: 0.56rem;
  margin-top: 0.20rem;
}

.profession .content_item_right_image {
  position: absolute;
  left: 5.80rem;
  top: -0.30rem;
  width: auto;
  height: 3.90rem;
  opacity: 1;
  transform: scale(1);
  transition: all ease-out 0.6s;
}

.profession .content_image {
  position: relative;
  float: left;
  width: 100%;
}
</style>
