<template>
  <div class="about-m">
    <div class="ab-1">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/ban-1.jpg" width="100%"/>
    </div>
    <div class="ab ab-5">
      <div class="title-g" style="text-align: center">
        <p class="title">加入我们</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <div class="items">
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/ban-3.jpg" width="100%"/>
          <div class="layer">
            <el-row :gutter="16">
              <el-col :span="16">
                <p class="txt-1">司机招募</p>
                <p class="txt-2">咱板车司机的自己找货平台，海量货源、永不收取会员费、降低欺诈风险、提升司机收益、打造智能、便利、安全有保障的一站式司机运输服务平台。</p>
              </el-col>
              <el-col :span="8">
                <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/car.jpg" width="100%" class="qr-code"/>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="items">
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/ban-4.jpg" width="100%"/>
          <div class="layer">
            <el-row :gutter="16">
              <el-col :span="16">
                <p class="txt-1">运力调度</p>
                <p class="txt-2">为承运商打造专业、智能、高效、共赢的运力管理平台，实现在线调车、在线结算、在线监控、在线购买保险等，一站式智能调度服务。</p>
              </el-col>
              <el-col :span="8">
                <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/owner.jpg" width="100%" class="qr-code"/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div class="ab ab-6">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/ban-5.jpg" width="100%"/>
      <div class="layer-form">
        <p class="title-1">物流承运商加盟</p>
        <p class="title-2">我们会尽快给您回复，期待您的加入</p>
        <el-form ref="form" :model="form" label-width="0" class="form-sub">
          <el-form-item>
            <el-input v-model="form.name" placeholder="姓名"/>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.phone" placeholder="手机号"/>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.company" placeholder="企业名称"/>
          </el-form-item>
        </el-form>
        <el-button type="primary" round class="sub-btn" @click="sub">加入我们</el-button>
      </div>
    </div>
    <div class="ab ab-7">
      <div class="title-g" style="text-align: center">
        <p class="title">联系我们</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <p class="txt-1">如果您有什么好的想法和意见，欢迎随时联系我们</p>
        <div id="container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "about",
  data() {
    return {
      url: 'https://www.zhiyuntongkj.com/prod/user/website/partnerInput',
      form: {
        name: '',
        phone: '',
        company: ''
      }
    }
  },
  mounted() {
    let marker = '';
    let map = new AMap.Map("container", {
      resizeEnable: true,
      center: [116.367198,40.099054],
      zoom: 17
    });
    marker = new AMap.Marker({
      icon: "https://test-1302681310.cos.ap-beijing.myqcloud.com/zyt/website/1620983560571_icon12.png",
      position: [116.367198,40.099054],
      offset: new AMap.Pixel(-13, -30)
    });
    marker.setMap(map);
  },
  methods: {
    sub() {
      axios.post(this.url, this.form).then(res => {
        console.log(res);
        if (res.data.code === 0) {
          this.$message.success('提交完成');
        } else {
          this.$message.error(res.data.message);
        }
      });
    }
  }
}
</script>

<style lang="less">
.about-m {
  padding-bottom: 46px;

  .title-g {
    margin-top: 55px;
    text-align: left;
    margin-bottom: 28px;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #222222;
      line-height: 30px;
      letter-spacing: 1px;
    }

    .line {
      width: 18px;
      height: 1px;
      background: #0274F6;
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 8px;
    }

    .detail {
      font-size: 14px;
      font-weight: 500;
      color: #222222;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }

  .ab {
    padding: 0 18px;
  }

  .ab-5 {
    .content {
      .items {
        position: relative;
        margin-bottom: 27px;

        .layer {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          //bottom: 0;
          background: rgb(33 33 33 / 24%);
          padding: 41px 18px 0 18px;

          .txt-1 {
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 20px;
          }

          .txt-2 {
            margin-top: 4px;
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
          }

          .qr-code {
            border-radius: 8px;
          }
        }
      }
    }
  }

  .ab-6 {
    padding: 0;
    position: relative;

    .layer-form {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 28px 24px 0 24px;

      .title-1 {
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 30px;
      }

      .title-2 {
        margin-top: 6px;
        margin-bottom: 33px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
      }

      .form-sub {
        .el-form-item {
          margin-bottom: 22px;

          .el-input {
            .el-input__inner {
              padding: 15px 19px;
              height: 50px;
              background: rgb(15 21 64 / 30%);
              border-radius: 2px;
              border: 1px solid rgb(255 255 255 / 30%);;
              font-size: 16px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22px;
            }
          }
        }
      }

      .sub-btn {
        border-radius: 25px;
        background: #0274F6;
        border: 0;
        padding: 12px 35px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 25px;
        margin-top: 60px;
      }
    }
  }

  .ab-7 {
    .content {
      padding: 0 10px;

      .txt-1 {
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: #222222;
        line-height: 18px;
        margin-bottom: 24px;
      }

      #container {
        width: 100%;
        height: 120vw;

        .amap-icon {
          overflow: visible !important;
        }

        .amap-icon img,
        .amap-marker-content img {
          width: 25px;
          height: 34px;
        }

        .marker {
          position: absolute;
          top: -20px;
          right: -118px;
          color: #fff;
          padding: 4px 10px;
          box-shadow: 1px 1px 1px rgba(10, 10, 10, .2);
          white-space: nowrap;
          font-size: 12px;
          font-family: "";
          background-color: #25A5F7;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>
