<template>
  <el-container>
    <el-header class="el-mh">
      <top1/>
    </el-header>
    <el-main class="el-mains" ref="elna">
      <router-view></router-view>
    </el-main>
    <el-footer>
      <btm1/>
    </el-footer>
  </el-container>
</template>

<script>
import top1 from "@/components/h5/headers";
import btm1 from "@/components/h5/footers";

export default {
  name: "index",
  components: {
    top1, btm1
  },
  mounted() {
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      if (this.$route.path.indexOf('/m') == -1) {
        this.$router.push('/m' + this.$route.path);
      }
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      console.log(this.$route.path.indexOf('/m'));
      if (this.$route.path.indexOf('/m') == 0) {
        this.$router.push(this.$route.path.substr(2));
      }
    }
  },
  methods: {
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  }
}
</script>

<style lang="less">
.el-container {
  .el-header {
    padding: 0;
  }

  .el-mains {
    margin-top: 60px;
    overflow: hidden;
  }

  .el-mh {
    background: #0274F6;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
  }

  .title-group {
    padding: 0 18px;

    &.right {
      text-align: right;
    }

    &.left {
      text-align: left;
    }

    &.center {
      text-align: center;
    }

    .title-1 {
      font-size: 22px;
      font-weight: 600;
      color: #222222;
      line-height: 30px;
    }

    .line {
      width: 18px;
      height: 1px;
      display: inline-block;
      background: #0274F6;
      margin: 5px auto;
    }
  }
}
</style>
