<template>
  <div class="profession-m">
    <div class="pro-1">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/profession/ban-1.jpg" width="100%"/>
    </div>
    <div class="pro-2">
      <div class="title-group left">
        <p class="title-1">技术专利</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <p class="txt-1">轴线车转弯半径计算程序</p>
        <p class="txt-2">本系统应用于大型车辆运输，操作简单，只需输入车型的系数便可计算出结果。</p>
        <p class="txt-2">测算出需要的转弯半径，从而避免在通过弯道时因车货过长转弯半径不够而导致运输受阻。本系统操作简便，只需输入车型的系数便可计算出结果</p>
        <p class="txt-1" style="margin-top: 55px">轴线车转弯半径计算程序</p>
        <el-row :gutter="6" style="margin-top: 6px">
          <el-col :span="12">
            <p class="txt-2">
              本系统应用于大型车辆运输，根据车型的系数，对车辆装载货物所需的最大牵引力及爬坡度进行计算，从而避免因货物超载导致运输过程中引发事故。本系统操作简便，只需输入车型的系数便可计算出结果。</p>
          </el-col>
          <el-col :span="12">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/profession/ban-2.png"
                 width="100%"/>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="pro-3">
      <div class="title-group left">
        <p class="title-1">专利证书</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <p class="txt-1">轴线车转弯半径计算程序</p>
      </div>
      <div class="lb-1">
        <el-carousel :interval="4000" type="card" arrow="never" height="310px" ref="pro3">
          <el-carousel-item v-for="item in 12" :key="item">
            <img
                :src="'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/profession/lb-1-' +(item+1)+'.jpg'"
                width="100%"/>
          </el-carousel-item>
        </el-carousel>
        <el-row :gutter="12">
          <el-col :span="12">
            <i class="el-icon-arrow-left icon-diy" @click="arrowClick"/>
          </el-col>
          <el-col :span="12">
            <i class="el-icon-arrow-right icon-diy" @click="arrowClick('right')"/>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="pro-4">
      <div class="titles-g">
        <p class="title">专业资质</p>
        <div class="line"></div>
      </div>
      <div class="lb-1">
        <el-carousel :interval="5000" arrow="never" ref="pro4">
          <el-carousel-item v-for="item in 9" :key="item">
            <img v-if="item === 0||item === 1||item === 5"
                 :src="'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/profession/lb-2-'+(item+1)+'.jpg'"
                 width="60%"/>
            <img v-else
                 :src="'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/profession/lb-2-'+(item+1)+'.jpg'"
                 width="100%"/>
          </el-carousel-item>
        </el-carousel>
        <el-row :gutter="12">
          <el-col :span="12">
            <i class="el-icon-arrow-left icon-diy fff" @click="arrowClick1"/>
          </el-col>
          <el-col :span="12">
            <i class="el-icon-arrow-right icon-diy fff" @click="arrowClick1('right')"/>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="pro-5">
      <div class="titles-g">
        <p class="title">运输资质</p>
        <div class="line"></div>
      </div>
      <div class="lb-1">
        <el-carousel :interval="5000" arrow="never" ref="pro5">
          <el-carousel-item v-for="item in 3" :key="item">
            <img
                :src="'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/profession/lb-3-'+(item+1)+'.jpg'"
                width="100%"/>
          </el-carousel-item>
        </el-carousel>
        <el-row :gutter="12">
          <el-col :span="12">
            <i class="el-icon-arrow-left icon-diy fff" @click="arrowClick2"/>
          </el-col>
          <el-col :span="12">
            <i class="el-icon-arrow-right icon-diy fff" @click="arrowClick2('right')"/>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="pro-6">
      <div class="titles-g">
        <p class="title" style="color: #222222">合作案例</p>
        <div class="line" style="background: #0274F6"></div>
      </div>
      <div class="lb-1">
        <el-carousel :interval="4000" type="card" height="200px" arrow="never" ref="pro6">
          <el-carousel-item>
            <div class="e-c-item" @click="showVideo(0)">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/10.jpg"
                   width="100%"/>
              <p class="title">JSTV_JSCS报道火车车厢运输</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item" @click="showVideo(1)">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/11.jpg"
                   width="100%"/>
              <p class="title">南京百吨“庞然大物”通行难 一路拆信号灯</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item" @click="showVideo(2)">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/12.jpg"
                   width="100%"/>
              <p class="title">国产最大氨合成塔运输</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/1.jpg" width="100%"/>
              <p class="title">西门子出口变压器</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/2.jpg" width="100%"/>
              <p class="title">PC单轨梁运输</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/3.jpg" width="100%"/>
              <p class="title">长途运输最重件525t</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/4.jpg" width="100%"/>
              <p class="title">长途运输最大直径5.3m</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/5.jpg" width="100%"/>
              <p class="title">顺风大运专车</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/6.jpg" width="100%"/>
              <p class="title">长途运输最长哈氏合金塔器71m</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/7.jpg" width="100%"/>
              <p class="title">隧道管片</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="e-c-item">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/lb/9.jpg" width="100%"/>
              <p class="title">二航局运梁照片</p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-row :gutter="12">
          <el-col :span="12">
            <i class="el-icon-arrow-left icon-diy" @click="arrowClick3"/>
          </el-col>
          <el-col :span="12">
            <i class="el-icon-arrow-right icon-diy" @click="arrowClick3('right')"/>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog title="视频播放" :visible.sync="videoDialogVisible" width="100%" center @close="closeVideo">
      <video ref="vueMiniPlayer" :src="videoUrl" :controls="videoOptions.controls"
             class="video-js vjs-big-play-centered vjs-fluid" webkit-playsinline="true" playsinline="true"
             x-webkit-airplay="allow" x5-playsinline style="width: 100%;" autoplay="autoplay"/>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "profession",
  data() {
    return {
      videoDialogVisible: false,
      videoOptions: {controls: true},
      videoList: ['https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/n1.mp4', 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/n2.mp4', 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/n3.mp4'],
      videoUrl: '',
    }
  },
  methods: {
    closeVideo() {
      this.$refs.vueMiniPlayer.pause();
    },
    showVideo(e) {
      this.videoUrl = this.videoList[e];
      this.videoDialogVisible = true;
    },
    arrowClick(val) {
      if (val === 'right') {
        this.$refs.pro3.next();
      } else {
        this.$refs.pro3.prev();
      }
    },
    arrowClick1(val) {
      if (val === 'right') {
        this.$refs.pro4.next();
      } else {
        this.$refs.pro4.prev();
      }
    },
    arrowClick2(val) {
      if (val === 'right') {
        this.$refs.pro5.next();
      } else {
        this.$refs.pro5.prev();
      }
    },
    arrowClick3(val) {
      if (val === 'right') {
        this.$refs.pro6.next();
      } else {
        this.$refs.pro6.prev();
      }
    },
  }
}
</script>

<style lang="less">
.profession-m {
  .pro-2 {
    margin-top: 39px;

    .content {
      text-align: left;
      padding: 0 18px;

      .txt-1 {
        font-size: 14px;
        font-weight: bold;
        color: #222222;
        line-height: 20px;
        letter-spacing: 1px;
      }

      .txt-2 {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }
  }

  .pro-3 {
    .content {
      padding: 0px 18px;

      .txt-1 {
        font-size: 14px;
        font-weight: 600;
        color: #222222;
        line-height: 20px;
        letter-spacing: 1px;
        text-align: left;
        margin-bottom: 52px;
      }

    }

    .el-carousel__indicators--outside {
      display: none;
    }
  }

  .lb-1 {
    margin-bottom: 20px;

    .icon-diy {
      font-size: 30px;
      font-weight: bold;

      &.fff {
        color: #FFFFFF;
        margin-top: 10px;
      }
    }
  }

  .titles-g {
    text-align: center;

    .title {
      font-size: 22px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 30px;
    }

    .line {
      display: inline-block;
      margin: 8px auto;
      width: 18px;
      height: 1px;
      background: #FFFFFF;
    }
  }

  .pro-4, .pro-5 {
    background: rgba(2, 116, 246, 0.9);
    padding: 33px 20px;

    .el-carousel__indicators {
      display: none;
    }
  }

  .pro-6 {
    margin-top: 83px;

    .lb-1 {
      .e-c-item {
        position: relative;

        .title {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 5px;
          text-align: center;
          height: 27px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
        }
      }

      .el-carousel__indicators {
        display: none;
      }
    }

  }
}
</style>
