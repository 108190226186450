<template>
  <div class="home-m">
    <div class="con-1">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assetsv2/h5/home/banner.png" width="100%"/>
    </div>
    <div class="con-2">
      <div class="title-group right">
        <p class="title-1">平台资源</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <p class="txt-1">截止到目前公司合作物流承运商<span class="tagging">20000多</span>家</p>
        <p class="txt-1">签约专业运力 <span class="tagging">100000多</span>辆</p>
        <el-row class="groups">
          <el-col :span="5">
            <p class="txt-2">10000<span class="black">+</span></p>
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-i-1.png" class="img-1"/>
            <p class="txt-3">货主</p>
          </el-col>
          <el-col :span="4">
            <p class="txt-2">100000<span class="black">+</span></p>
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-i-2.png" class="img-1"/>
            <p class="txt-3">平台车</p>
          </el-col>
          <el-col :span="4">
            <p class="txt-2">20000<span class="black">+</span></p>
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-i-3.png" class="img-1"/>
            <p class="txt-3">日订单</p>
          </el-col>
          <el-col :span="6">
            <p class="txt-2">6<span class="black">大运营中心</span></p>
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-i-4.png" class="img-1"/>
            <p class="txt-3">全国运力布局</p>
          </el-col>
          <el-col :span="5">
            <p class="txt-2">1000<span class="black">+</span></p>
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-i-5.png" class="img-1"/>
            <p class="txt-3">运输专线</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="con-3">
      <div class="title-group left">
        <p class="title-1">安全监控</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <p class="txt-2">基于物联网、5G、电子围栏，</p>
        <p class="txt-2">DSM系统，实现实时、智能、集约、远程的自动化车辆管控</p>
        <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/ban-3.png" width="100%"/>
      </div>
    </div>
    <div class="con-4">
      <div class="title-group center">
        <p class="title-1">核心技术</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <p class="txt-1">基于海量历史数据和物联网远程采集，大数据实时计算，<br>集合行业专家经验，构建了顺风大运的核心技术壁垒</p>
        <div class="areaList">
          <div class="items">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-1.jpg" width="100%"/>
            <div class="layer">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-i-1.png" width="57" style="margin-top: 58px"/>
              <p class="txt-2">智能调度</p>
              <p class="txt-3">毫秒级响应、百万级并发、大数据实时计算</p>
            </div>
          </div>
          <div class="items">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-2.jpg" width="100%"/>
            <div class="layer">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-i-2.png" width="57" style="margin-top: 58px"/>
              <p class="txt-2">专家模型</p>
              <p class="txt-3">路线规划、自动报价、智能匹配</p>
            </div>
          </div>
          <div class="items">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-3.jpg" width="100%"/>
            <div class="layer">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-i-3.png" width="57" style="margin-top: 58px"/>
              <p class="txt-2">AI智能</p>
              <p class="txt-3">车联网、AI智能、用户画像、可视化技术、科技物流</p>
            </div>
          </div>
          <div class="items">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-4.jpg" width="100%"/>
            <div class="layer">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-i-4.png" width="57" style="margin-top: 58px"/>
              <p class="txt-2">运力矩阵</p>
              <p class="txt-3">性格、年龄、驾驶行为、服务评级、服务水平、专业技能</p>
            </div>
          </div>
          <div class="items">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-5.jpg" width="100%"/>
            <div class="layer">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-i-5.png" width="57" style="margin-top: 58px"/>
              <p class="txt-2">区块链</p>
              <p class="txt-3">合规、诚信、金融、信息对称</p>
            </div>
          </div>
          <div class="items">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-6.jpg" width="100%"/>
            <div class="layer">
              <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/h-4-i-6.png" width="57" style="margin-top: 58px"/>
              <p class="txt-2">无人驾驶</p>
              <p class="txt-3">顺风大运基于物流场景和无人驾驶技术相结合，<br/>构建了具有商业价值的无人驾驶应用场景</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="con-6">
<!--      <div class="title-group center">-->
<!--        <p class="title-1">关于我们</p>-->
<!--        <div class="line"></div>-->
<!--      </div>-->
      <div class="content">
        <div class="ab">
          <div class="title-g">
            <p class="title">公司简介</p>
            <div class="line"></div>
            <p content="detail">顺风大运目标是打造专业、高效、智能、便捷的大件智能专车平台。</p>
            <br>
            <p content="detail">
              顺风大运是基于大数据智能调度，把无序的物流变为时间有序、空间有序，实现计划式的车辆闭环线路；目的是降低空驶率，降低车辆运营成本，进而提升车辆有效运输效率，所创造的超额利润空间由车方、货方、平台分享。通过专车智能调度平台，降低了空驶率，提升了车辆使用效率，降低了设备运输价格，实现了企业、司机、平台三方收益。</p>
            <p content="detail">时代的趋势、市场的机遇、政策的东风、完美的团队都预示着顺风大运的项目是天时地利人和。四张王牌的创始团队，协同资本的助力，一起打造顺风大运的万亿市值！未来已来！相信伟大！</p>
          </div>
          <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/ban-2.png" width="100%"/>
        </div>
        <div class="ab">
          <div class="title-g">
            <p class="title">公司愿景</p>
            <div class="line"></div>
            <p content="detail">打造专业、高效、智能、便捷的大件专车智能服务平台</p>
          </div>
        </div>
        <div class="ab">
          <div class="title-g">
            <p class="title">企业文化</p>
            <div class="line"></div>
            <p content="detail">客户第一、互利互赢、阳光诚信、公平公正、结果导向、迭代创新、追求卓越！</p>
          </div>
        </div>
      </div>
    </div>
    <div class="con-5">
      <div class="title-group center">
        <p class="title-1">合作伙伴</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/ban-5.png" width="100%"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home"
}
</script>

<style lang="less">
.home-m {
  .con-1 {
    img {
      width: 100%;
    }
  }

  .con-2 {
    margin-top: 58px;

    .content {
      padding: 0 18px;
      background: url("https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/home/ban-2.jpg") no-repeat center;
      background-size: contain;
      min-height: 110vw;
      text-align: right;
      position: relative;

      .txt-1 {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        line-height: 24px;

        .tagging {
          color: #0274F6;
        }
      }

      .groups {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        .el-col {
          text-align: center;

          .img-1 {
            width: 22px;
          }

          .txt-2 {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: #0274F6;
            line-height: 20px;

            .black {
              color: #000000;
            }
          }

          .txt-3 {
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
          }
        }

      }
    }
  }

  .con-3 {
    margin-top: 36px;

    .content {
      text-align: left;

      .txt-1 {
        padding-left: 18px;
        font-size: 14px;
        font-weight: 600;
        color: #222222;
        line-height: 20px;
        letter-spacing: 1px;
      }

      .txt-2 {
        padding-left: 18px;
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }
  }

  .con-4 {
    margin-top: 40px;

    .content {
      text-align: center;

      .txt-1 {
        font-size: 13px;
        font-weight: 400;
        color: #222222;
        line-height: 18px;
        margin-bottom: 30px;
      }

      .areaList {
        .items {
          position: relative;

          .layer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;

            .txt-2 {
              margin-top: 12px;
              font-size: 15px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 21px;
            }

            .txt-3 {
              margin: 0 auto;
              width: 289px;
              height: 80px;
              font-size: 13px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .con-5 {
    margin-top: 50px;

    .content {
      margin-top: 22px;
      padding: 0 12px 46px 12px;
    }
  }

  .con-6 {
    margin-top: 50px;

    .content {
      margin-top: 22px;
      padding: 0 12px 0px 12px;

      .title-g {
        margin-top: 55px;
        text-align: left;
        margin-bottom: 28px;

        .title {
          font-size: 18px;
          font-weight: 800;
          color: #222222;
          line-height: 30px;
          letter-spacing: 1px;
        }

        .line {
          width: 18px;
          height: 1px;
          background: #0274F6;
          display: inline-block;
          margin-top: 5px;
          margin-bottom: 8px;
        }

        .detail {
          font-size: 14px;
          font-weight: 500;
          color: #222222;
          line-height: 20px;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>
