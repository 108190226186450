<template>
  <div class='homediv'>
    <img class='homeimage' src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assetsv2/home/home1.png'/>
    <div class='homeimagecontent0'>
      <img class='homeimage' src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/home2.png'/>
      <div class='homeimagecontenttext0 homeimagecontenttext6'>平台资源</div>
      <div class='homeimagecontentline'></div>
      <div class='homeimagecontenttext0'>截止到目前公司合作物流承运商<span>20000多</span>家</div>
      <div class='homeimagecontenttext0 homeimagecontenttext1'>签约专业运力<span>100000多</span>辆</div>
      <div class='homeimagecontent'>
        <div class='homeimagecontentitem'>
          <div class='homeimagecontentitemtitle'>10000<span>+</span></div>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/image1.png' class='homeimagecontentitemtitle0'/>
          <div class='homeimagecontentitemtitle1'>货主</div>
        </div>
        <div class='homeimagecontentitem'>
          <div class='homeimagecontentitemtitle'>100000<span>+</span></div>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/image2.png' class='homeimagecontentitemtitle0'/>
          <div class='homeimagecontentitemtitle1'>平台车</div>
        </div>
        <div class='homeimagecontentitem'>
          <div class='homeimagecontentitemtitle'>20000<span>+</span></div>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/image3.png' class='homeimagecontentitemtitle0'/>
          <div class='homeimagecontentitemtitle1'>日订单</div>
        </div>
        <div class='homeimagecontentitem'>
          <div class='homeimagecontentitemtitle'>6<span>大运营中心</span></div>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/image4.png' class='homeimagecontentitemtitle0'/>
          <div class='homeimagecontentitemtitle1'>全国运力布局</div>
        </div>
        <div class='homeimagecontentitem'>
          <div class='homeimagecontentitemtitle'>1000<span>+</span></div>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/image5.png' class='homeimagecontentitemtitle0'/>
          <div class='homeimagecontentitemtitle1'>运输专线</div>
        </div>
      </div>
    </div>
    <div class='homeimagecontent1'>
      <img class='homeimage0' src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/home3.jpg'/>
      <div class='homeimagecontent1text'>安全监控</div>
      <div class='homeimagecontent1textline'></div>
      <!--
      <div class='homeimagecontent1text homeimagecontent1text1'>可视化、车联网、智能调度实时远程监控</div>
      -->
      <div class='homeimagecontent1text homeimagecontent1text6'>基于物联网、5G、电子围栏，</div>

      <div class='homeimagecontent1text homeimagecontent1text9'>DSM系统，实现实时、智能、集约、远程的自动化车辆管控</div>

    </div>
    <div class='hometitlediv'>
      <div class='hometitle'>核心技术</div>
      <div class='hometitleline'></div>
      <div class='hometitledesc'>基于海量历史数据和物联网远程采集，大数据实时计算，集合行业专家经验，构建了顺风大运的核心技术壁垒</div>
    </div>
    <div class='homeitem'>
      <div class='homeitem0'>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item1.jpg' class='homeitemimagebg0'/>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item0.jpg' class='homeitemimagebg'/>
        <div class='contentimage'>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/icon1.png' class='homeitemimage'/>
          <div class='homeitemtitle'>智能调度</div>
          <div class='homeitemdesc'>毫秒级响应、百万级并发、大数据实时计算</div>
        </div>
      </div>
      <div class='homeitem0'>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item1.jpg' class='homeitemimagebg0'/>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item2.png' class='homeitemimagebg'/>
        <div class='contentimage'>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/icon2.png' class='homeitemimage'/>
          <div class='homeitemtitle'>专家模型</div>
          <div class='homeitemdesc'>路线规划、自动报价、智能匹配</div>
        </div>
      </div>
      <div class='homeitem0'>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item1.jpg' class='homeitemimagebg0'/>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item3.jpg' class='homeitemimagebg'/>
        <div class='contentimage'>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/icon3.png' class='homeitemimage'/>
          <div class='homeitemtitle'>人工智能</div>
          <div class='homeitemdesc'>车联网、人工智能、用户画像、可视化技术、科技物流</div>
        </div>
      </div>
      <div class='homeitem0'>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item1.jpg' class='homeitemimagebg0'/>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item4.jpg' class='homeitemimagebg'/>
        <div class='contentimage'>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/icon4.png' class='homeitemimage'/>
          <div class='homeitemtitle'>运力矩阵</div>
          <div class='homeitemdesc'>性格、年龄、驾驶行为、服务评级、服务水平、专业技能</div>
        </div>
      </div>
      <div class='homeitem0'>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item1.jpg' class='homeitemimagebg0'/>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item5.jpg' class='homeitemimagebg'/>
        <div class='contentimage'>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/icon5.png' class='homeitemimage'/>
          <div class='homeitemtitle'>区块链</div>
          <div class='homeitemdesc'>合规、诚信、金融、信息对称</div>
        </div>
      </div>
      <div class='homeitem0'>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item1.jpg' class='homeitemimagebg0'/>
        <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/item6.jpg' class='homeitemimagebg'/>
        <div class='contentimage'>
          <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/icon6.png' class='homeitemimage'/>
          <div class='homeitemtitle'>无人驾驶</div>
          <div class='homeitemdesc'>顺风大运基于物流场景和无人驾驶技术相结合，<br/>构建了具有商业价值的无人驾驶应用场景</div>
        </div>
      </div>
    </div>
<!--    <div class='hometitlediv'>-->
<!--      <div class='hometitle'>关于我们</div>-->
<!--      <div class='hometitleline'></div>-->
<!--    </div>-->
    <div class="homeitem1 contain">
      <div class="main">
        <el-row :gutter="10">
          <el-col :span="14" class="left">
            <p class="title">公司简介</p>
            <div class="line"></div>
            <p class="introduce">顺风大运目标是打造专业、高效、智能、便捷的大件智能专车平台。</p>
            <p class="introduce">
              顺风大运是基于大数据智能调度，把无序的物流变为时间有序、空间有序，实现计划式的车辆闭环线路；目的是降低空驶率，降低车辆运营成本，进而提升车辆有效运输效率，所创造的超额利润空间由车方、货方、平台分享。通过专车智能调度平台，降低了空驶率，提升了车辆使用效率，降低了设备运输价格，实现了企业、司机、平台三方收益。
            </p>
            <p class="introduce">
              时代的趋势、市场的机遇、政策的东风、完美的团队都预示着顺风大运的项目是天时地利人和。四张王牌的创始团队，协同资本的助力，一起打造顺风大运的万亿市值！未来已来！相信伟大！
            </p>
          </el-col>
          <el-col :span="10">
            <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/about/ab-1.jpg" width="100%"/>
          </el-col>
        </el-row>
        <div class="ban-2">
          <p class="title">公司愿景</p>
          <div class="line"></div>
          <p class="content">
            打造专业、高效、智能、便捷的大件专车智能服务平台
          </p>
        </div>
        <div class="ban-2">
          <p class="title">企业文化</p>
          <div class="line"></div>
          <p class="content">
            客户第一、互利互赢、阳光诚信、公平公正、结果导向、迭代创新、追求卓越！
          </p>
        </div>
      </div>
    </div>
    <div class='hometitlediv'>
      <div class='hometitle'>合作伙伴</div>
      <div class='hometitleline'></div>
    </div>
    <div class='homeitem1'>
      <img src='https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/home/a1.jpg' class='homeitem1image'/>
    </div>
  </div>
</template>

<script>
export default {
  name: "home"
}
</script>

<style lang="less" scoped>
body {
  margin: 0;
  padding: 0;
}

.homediv {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 0.90rem;
  overflow: hidden;

  .main {
    padding-top: 1.06rem;
    font-size: 12px;

    .title {
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: #222222;
      line-height: 1.2em;
      margin: 0;
    }

    .line {
      width: 34px;
      height: 2px;
      background: #0274F6;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .left {
      text-align: left;

      .introduce {
        font-size: 18px;
        font-weight: 400;
        color: #222;
        line-height: 1.5em;
        margin-bottom: 0.15rem;
      }
    }

    .ban-2 {
      text-align: left;
      margin-top: 0.35rem;

      .content {
        font-size: 18px;
        font-weight: 400;
        color: #222;
        line-height: 0.3rem;
      }
    }
  }
}

.homediv .homeimage {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 5.00rem;
}

.homediv .homeitem0 .contentimage {
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 34%;
  transform: translateY(-50%);
  z-index: 18;
  transition: all linear 0.6;
}

.homediv .homeitem0:hover .contentimage {
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 32%;
  transform: translateY(-50%);
  z-index: 18;
}

.homediv .homeimagecontent1 {
  position: relative;
  float: left;
  width: 62%;
  margin-left: 50%;
  transform: translateX(-50%);
  height: auto;
  margin-top: 0.50rem;
}

.homediv .homeimage0 {
  position: relative;
  float: left;
  width: 100%;
  margin-left: 0rem;
  height: auto;
  margin-top: 0rem;
}

.homediv .homeimagecontent1text {
  position: absolute;
  width: auto;
  height: 0.60rem;
  line-height: 0.60rem;
  font-size: 0.30rem;
  font-weight: bold;
  top: 0.09rem;
  left: 0.24rem;
  color: #222;
}

.homediv .homeimagecontent1textline {
  position: absolute;
  width: 0.30rem;
  height: 0.03rem;
  left: 0.26rem;
  top: 0.76rem;
  background-color: #0274F6;
}

.homediv .homeimagecontent1text1 {
  height: 0.36rem;
  line-height: 0.36rem;
  font-size: 0.20rem;
  top: 0.89rem;
  left: 0.24rem;
}

.homediv .homeimagecontent1text6 {
  height: 0.30rem;
  line-height: 0.30rem;
  font-size: 0.16rem;
  top: 1.05rem;
  left: 0.26rem;
  font-weight: normal;
}

.homediv .homeimagecontent1text9 {
  height: 0.30rem;
  line-height: 0.30rem;
  font-size: 0.16rem;
  top: 1.34rem;
  left: 0.26rem;
  font-weight: normal;
}

.homediv .hometitlediv {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 0.80rem;
}

.homediv .hometitle {
  position: relative;
  float: left;
  width: 100%;
  height: 0.56rem;
  font-size: 0.30rem;
  line-height: 0.56rem;
  font-weight: bold;
  text-align: center;
  color: #222;
}

.homediv .hometitleline {
  position: relative;
  float: left;
  width: 0.34rem;
  height: 0.04rem;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #0274F6;
}

.homediv .hometitledesc {
  position: relative;
  float: left;
  width: 100%;
  height: 0.26rem;
  font-size: 0.16rem;
  line-height: 0.26rem;
  color: #222;
  text-align: center;
  margin-top: 0.10rem;
  font-weight: normal;
}

.homediv .homeitem {
  position: relative;
  float: left;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0.30rem;
}

.homediv .homeitem0 {
  position: relative;
  float: left;
  width: 33.3333333%;
  height: auto;
  overflow: hidden;
}

.homediv .homeitemimage {
  position: relative;
  float: left;
  height: 0.60rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0rem;
  z-index: 18;
}

.homediv .homeitemtitle {
  position: relative;
  float: left;
  width: 100%;
  height: 0.32rem;
  line-height: 0.32rem;
  font-size: 0.24rem;
  color: #fff;
  text-align: center;
  margin-top: 0.10rem;
  z-index: 18;
}

.homediv .homeitem0 .homeitemimagebg0 {
  position: absolute;
  width: 100%;
  height: auto;
  left: 50%;
  top: 50%;
  z-index: 10;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.2);
  transition: all linear 0.3s;
}

.homediv .homeitem0:hover .homeitemimagebg0 {
  position: absolute;
  width: 100%;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  z-index: 10;
  opacity: 1;
}

.homediv .homeitemdesc {
  position: relative;
  float: left;
  width: 90%;
  height: auto;
  font-size: 0.14rem;
  line-height: 0.26rem;
  margin-top: 0.10rem;
  color: #fff;
  left: 5%;
  z-index: 18;
}

.homediv .homeitemimagebg {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  z-index: 9;
}

.homediv .homeitem1 {
  position: relative;
  float: left;
  width: 12.20rem;
  height: auto;
  margin-left: 50%;
  padding-bottom: 1.50rem;
  transform: translateX(-50%);
  margin-top: 0.30rem;
}

.homediv .homeitem1image {
  position: relative;
  float: left;
  width: auto;
  height: 5.55rem;
  margin-left: 50%;
  margin-top: 0.10rem;
  transform: translateX(-50%);
}

.homediv .homeimagecontent0 {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.homediv .homeimagecontent0 .homeimagecontent {
  position: absolute;
  top: 65%;
  width: 73%;
  left: 50%;
  transform: translateX(-50%);
  height: 2.00rem;
}

.homediv .homeimagecontentitem {
  position: relative;
  float: left;
  width: 20%;
  height: 2.00rem;
}

.homediv .homeimagecontentitemtitle {
  position: relative;
  float: left;
  width: 100%;
  height: 0.66rem;
  font-size: 0.36rem;
  font-weight: bold;
  line-height: 0.66rem;
  text-align: center;
  color: #0274F6;
}

.homediv .homeimagecontentitemtitle span {
  font-size: 0.28rem;
  color: #222;
}

.homediv .homeimagecontentitemtitle0 {
  position: relative;
  float: left;
  width: auto;
  height: 0.43rem;
  margin-left: 50%;
  transform: translateX(-50%);
}

.homediv .homeimagecontentitemtitle1 {
  position: relative;
  float: left;
  width: 100%;
  height: 0.30rem;
  line-height: 0.30rem;
  font-size: 0.18rem;
  font-weight: bold;
  color: #222;
  margin-top: 0.10rem;
}

.homediv .homeimagecontenttext0 {
  position: absolute;
  width: auto;
  height: 0.30rem;
  top: 43%;
  right: 20.5%;
  font-size: 0.16rem;
  line-height: 0.30rem;
  color: #222;
  font-weight: normal;
}

.homediv .homeimagecontenttext1 {
  top: 46.3%;
}

.homediv .homeimagecontenttext6 {
  height: 0.42rem;
  line-height: 0.42rem;
  font-size: 0.3rem;
  font-weight: bold;
  top: 35%;
}

.homediv .homeimagecontenttext0 span {
  color: #0274F6;
  font-weight: bold;
}

.homediv .homeimagecontentline {
  position: absolute;
  width: 0.33rem;
  height: 0.03rem;
  background-color: #0274F6;
  top: 41%;
  right: 20.5%;
}
</style>
