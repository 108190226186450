<template>
  <el-container>
    <el-header class="el-h">
      <top/>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer>
      <btm/>
    </el-footer>
  </el-container>
</template>

<script>
import top from "@/components/headers";
import btm from "@/components/footers";

export default {
  name: "index",
  components: {
    top, btm
  },
  mounted() {
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      // console.log("yi移动端");
      if (this.$route.path.indexOf('/m') == -1) {
        this.$router.push('/m' + this.$route.path);
      }
    } else {
      // 为false是pc端，跳至pc端页面
      // console.log("PC端");
      // console.log(this.$route.path.indexOf('/m'));
      if (this.$route.path.indexOf('/m') == 0) {
        this.$router.push(this.$route.path.substr(2));
      }
    }
  },
  methods: {
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  }
}
</script>

<style lang="less">
.el-container {
  position: relative;

  .el-h {
    padding: 0;
    background: #0274F6;
    position: fixed;
    left: 0;
    right: 0;
    height: 0.90rem !important;
    top: 0;
    z-index: 999;
  }

  .el-main {
    min-height: 40vw;
    padding: 0;
    margin: 0;
  }

  .el-footer {
    background: #202B3E;
    height: auto !important;
  }
}

.contain {
  width: 12.00rem;
  margin: 0 auto;
}
</style>
