<template>
  <div class="newsDetail">
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/m/team' }">企业新闻</el-breadcrumb-item>
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <p class="tit">{{ newsList[active].title }}</p>
      <p class="time">{{ newsList[active].time }}</p>
      <p v-html="newsList[active].details"></p>
    </div>
    <div class="k"></div>
    <div class="news-list">
      <div class="items" v-for="(item,index) in newsList" :key="index" @click="$router.push('/m/newsDetail/'+index)">
        <p class="t-title">{{ item.title }}</p>
        <p class="t-detail">{{ item.brief }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newsDetailM",
  data() {
    return {
      active: 0,
      newsList: [
        {
          title: '工程机械租赁行业大咖论道逆境破局，加速重构市场新格局',
          brief: '2021年3月25-26日，以“逆境破局，加速重构！”为主题的2020年中国工程机械工业协会工程机械租赁分会年会在四川成都市隆重举行。本次会议是由中国工程机械工业协会指导，中国工程机械工业协会工程机械租赁分会主办。会议深度剖析当前形势下工程机械租赁面临的主要问题，共同探讨如何推进行业高质量发展，助力制造企业、租赁企业把握机遇，展望未来。',
          time: '2021-03-26',
          details: "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <strong><span style=\"margin: 0px; padding: 0px; border: none; outline: 0px; font: inherit; vertical-align: baseline; color: rgb(178, 34, 34);\">【中国路面机械网 柴喜男现场报道】</span></strong>2021年3月25-26日，以“逆境破局，加速重构！”为主题的2020年中国工程机械工业协会工程机械租赁分会年会在四川成都市隆重举行。本次会议是由中国工程机械工业协会指导，中国工程机械工业协会工程机械租赁分会主办。会议深度剖析当前形势下工程机械租赁面临的主要问题，共同探讨如何推进行业高质量发展，助力制造企业、租赁企业把握机遇，展望未来。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1440w772h20210326084900553.jpg!1024x1024.jpg\"/><br/><strong>2020年中国工程机械工业协会工程机械租赁分会年会现场</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    2020年注定是不平凡的一年。新冠疫情对全球经济产生了深远的影响。虽然2020年中国工程机械行业销售业绩迎来了新一轮的上升，但租赁行业整体形勢难言乐观。由于国内整机销售市场争进一步加剧，制造企业开始逐步向下游延伸;施工行业越来越趋向于轻资产运营，工程机械租赁渗透率随之逐年提高再加上近年来营改增、环保政策的影响以及5G、人工智能、互联网技术的快速代，多重叠加下的工程机械租赁市场如何在变化中开创新局，需要行业各界人士关注、支持与深入探讨。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326084952562.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>四川省工程机械商会租赁总会会长邱永朋致欢迎词</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    四川省工程机械商会租赁总会会长邱永朋表示，本届年会以 “逆境破局 加速重构”为主题，针对目前租赁行业现阶段的突出问题，以及上下游产业的变化对租赁行业的影响，租赁企业如何在变化中把握机会，重构企业竞争力，破旧局，开新局，找到企业未来的发展方向，赢得自身的发展空间，这些都需要行业各界人士的关注、支持和深入探讨。在这种形势下，中国工程机械工业协会工程机械租赁分会组织、汇聚行业各方智慧，在成都召开本次大会，对四川租赁业的发展意义非凡，对中国工程机械租赁行业未来的发展也将起到十分重要的作用。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326084925828.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>中国工程机械工业协会名誉会长祁俊作《工程机械行业发展分析及趋势展望》主题报告</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    中国工程机械工业协会名誉会长祁俊作主题发言。他的发言主要围绕工程机械行业2020年经济运行情况；工程机械行业当前经济运行特点和存在的问题；行业发展环境分析、展望与对策和当前行业主要工作四个方面展开。他预计2021年工程机械行业仍将保持增长，但他也表示出对价格战愈演愈烈趋势的担忧，同时他呼吁工程机械行业同仁理性竞争，确保企业合理的利润空间，共同推动工程机械行业健康、可持续发展。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1440w1080h20210326091247393.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1440w1080h20210326091304693.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1440w1080h20210326091324289.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>中国工程机械工业协会名誉会长祁俊作《工程机械行业发展分析及趋势展望》主题报告</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326093324680.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>发改委交通运输研究所主任郭文龙作《未来交通基础建设投资规划》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    发改委交通运输研究所主任郭文龙作《未来交通基础建设投资规划》主题发言，解读国民经济和社会发展第十四个五年规划和2035年远景目标，加快发展现代产业体系中，提到了统筹推进基础设施建设，完善大通道，大枢纽等基础设施建设。形成强大的国内市场，构建新的发展格局，提出拓展投资空间，补齐基础设施短板，推进新基建、新型城镇化、交通水利等重大工程。同时解读了《交通强国铁路先行规划纲要》、《新时代民航强国建设纲要》、《国家高速公路网规划》。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326104639183.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>上海庞源机械租赁有限公司副总经理王琛煜作《新机价格下降，租赁行业如何破局》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    上海庞源机械租赁有限公司副总经理王琛煜作《新机价格下降，租赁行业如何破局》主题发言，首先分析了塔机新机租金下降背后的逻辑，在破局方面，他指出租赁市场差异化竞争的策略。据统计，2020年市场存量的塔机有46万台。政策层面，2020年的装配式建筑面积达6.3亿平方米，占比20%，上海的装配式建筑的占比91.4%，未来对建筑式大型塔机的需要会增加。装配式建筑的优势是节能环保，安全，效率高，人力资源成本建设，减低成本。装配式建筑的快速发展必将带来塔机的快速在需求和技术方面进行迭代。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326104729632.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>顺风大运总裁李梓慧作《新形势下工程装备行业降本增效策略》的主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    顺风大运总裁李梓慧作《新形势下工程装备行业降本增效策略》的主题发言，分析了大件物流运输行业的现状，痛点是司机异地找货难，物流链条长、成本虚高，中间成本达到25%-35%；经营散乱差，诚信缺失等。顺丰大运建设了《一站式大件运输智能服务平台》，具有高效集约、融合开放；智能调度、协同共享等优点，是为大件物流行业提供全面解决方案的平台。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326113847291.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>日立建机（上海）有限公司客户课题解决方案事业部部长张显峰作《租赁的底层逻辑》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    日立建机（上海）有限公司客户课题解决方案事业部部长张显峰作《租赁的底层逻辑》主题发言，他分析了租赁业务的相关条件，其中提到，目前，租赁的渗透率在30%，他认为，主要把握住了租赁的三要素（损益表要盈利；现金流要管控；资产要管理）才能做好租赁业务。另外还分析了投资回报及成本核算的方法，为想进入租赁行业的企业提供参考。最后，他用“得租赁者得天下，得二手者傲群雄！”来评价租赁业务的重要性。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326115038783.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>荣峰科技&amp;管租易联合创始人张静作《租赁企业的跨越式发展》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    荣峰科技&amp;管租易联合创始人张静作《 租赁企业的跨越式发展》主题发言，首先解析了租赁业务现状、市场环境等的不确定性存在，通过<span style=\"margin: 0px; padding: 0px; border: none; outline: 0px; font: inherit; vertical-align: baseline; text-align: center;\">管租易在租赁行业的应用案例，在设备管理过程中的重要作用，赋能租赁企业跨越式发展。</span>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326015345134.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>普石勒（上海）机械设备销售有限责任公司总经理王业锋作《移动破碎设备的发展与未来》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    普石勒（上海）机械设备销售有限责任公司总经理王业锋作《移动破碎设备的发展与未来》主题发言，他认为环保和固废利用立法将移动破碎行业高速增长，建筑垃圾生产量2020年接近翻倍，砂石需求量的增长，逐年递增。移动破行业需求稳定增长，总需求将会占全球50%。移动破碎与筛分产品租赁市场方兴未艾。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326020620708.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>卡纳磨拓（中国）投资有限公司资产管理部覃峰作《立足细分领域 布局中国市场》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    卡纳磨拓（中国）投资有限公司资产管理部覃峰作《立足细分领域 布局中国市场》主题发言，矿山开采，铁路公路建设，<a href=\"https://www.lmjx.net/gaokongzuoyepingtai/\" target=\"_blank\" style=\"margin: 0px; padding: 0px; border: none; outline: 0px; font: inherit; vertical-align: baseline; color: rgb(13, 134, 241); text-decoration-line: none;\">高空作业平台</a>等租赁应用。一般租赁行业中存在付款不及时，租期短，设备使用少，门槛低，管理难度大等痛点。卡纳磨拓是专业租赁商中首家进入矿山设备租赁领域中的企业，在矿山市场汇总开疆拓土，给予矿山客户更多选择。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326023315811.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>四川猎石者龙宇科技有限公司董事长邱永朋作《差异化发展 价值创新》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    四川猎石者龙宇科技有限公司董事长邱永朋作《差异化发展 价值创新》主题发言，他首先介绍四川猎石者龙宇科技有限公司是一家针对细分市场有着深入研究的公司，有针对性的开发了开山豹系列属具，在市场获得广泛欢迎。他还分析了租赁市场情况，通用设备市场需求大，但是应用工况受限，针对差异化的设备，进行细分市场的属具进行开发，转型做专业性的设备，高利润周期，洞察客户的需求，带着施工方案，为客户创造价值方面去思考。优化客户结构，增强抵御风险的能力。他最后分享了租赁款回收的案例。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326023958166.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>四川西建山推物流有限公司总经理潘能志作《如何促进混凝土机械租赁行业的良性发展》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    四川西建山推物流有限公司总经理潘能志作《如何促进混凝土机械租赁行业的良性发展》主题发言，他首先分析了成都混凝土机械租赁市场的现状，存在市场分散，小规模企业居多，市场规则乱象丛生，盈利能力差异化明显，搅拌车以大方量的车为主，安全隐患高，租赁单价低等问题。呈现了低价竞争、运营风险高、设备使用率低、低价及低付款对租赁业的影响大等特点。未来趋势：行业整合集中度越来越高；搅拌车轻量化趋势、新技术应用；一线员工紧缺，成本急剧上升；经营专业化。在促进良性发展方面，建议保证合力的利润，行业资源整合，报团取暖，避免低价竞争，设备和人员的分流、合作、共享。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326033633944.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>湖南美石信息技术有限公司董事长李建作《互联网时代租赁企业的困局与破局》主题发言</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    湖南美石信息技术有限公司董事长李建作《互联网时代租赁企业的困局与破局》主题发言，他从宏观角度分析，租赁行业的快速增长，呈现井喷式上涨，但是在市场配套、人员等方面的市场软实力的不足，区域发展不平衡，回款难，资金垫付严重，缺少健康或者多头管理，缺乏良好的金融服务环境等等，阻碍了租赁行业健康发展的因素。租赁行业升级转型需求健康发展，需要通过互联网破局。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1440w851h20210326033713851.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>湖南美石信息技术有限公司董事长李建作互联网破局租赁行业的畅享</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <strong>工程机械租赁业论坛</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    工程机械租赁业论坛第一场，日立建机（上海）有限公司客户课题解决方案事业部部长张显峰主持论坛，参加对话的嘉宾是通冠机械租赁股份有限公司总经理张华、苏州法艾姆物流设备有限公司高机事业部总经理张海继、贵州运东机械有限公司&amp;贵州小松工程机械有限公司常务副总经理秦小川、法阁品牌联合创始人、江苏徽应律师事务所主任律师陈培培、华北利星行机械（北京）有限公司租赁部总经理朱德仁。各位嘉宾围绕“新机价格下降是机遇还是挑战”等方面话题进行了充分探讨。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w717h20210326040010943.jpg!1024x1024.jpg\"/><br/><strong>工程机械租赁业论坛第一场现场</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    工程机械租赁业论坛第二场，广西美斯达集团董事、副总裁余亚军主持论坛，出席对话的嘉宾是云南凯瑞特重工科技有限公司营销副总裁邓文锦、柳工美卓建筑设备（常州）有限公司总经理喻志勇、成都市启发工程机械有限公司总经理潘伟、四川猎石者龙宇科技有限公司董事长邱永朋。各位嘉宾针对“解构移动破碎筛分租赁市场”等方面话题进行了充分探讨。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1878w867h20210326045722401.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>工程机械租赁业论坛第二场现场</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1200h20210326054016336.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>工程机械租赁分会副会长兼秘书长李涵兵作主题为《中国工程机械租赁业发展趋势》的报告</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    中国工程机械工业协会工程机械租赁分会副会长兼秘书长李涵兵作主题为《中国工程机械租赁业发展趋势》的报告，报告中分析了2020年工程机械租赁行业发展现状。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w855h20210326054039730.jpg!1024x1024.jpg\"/>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong>报告中分析了2020年工程机械租赁行业发展现状</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <strong>中国工程机械工业协会工程机械租赁分会三届五次理事会召开</strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 15px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; white-space: normal; background-color: rgb(255, 255, 255); text-align: center;\">\n" +
              "    <strong><img alt=\"工程机械租赁行业大咖论道逆境破局，加速重构市场新格局\" src=\"https://news-static.lmjx.net/upload/2021/0326/1800w1071h20210326062852436.jpg!1024x1024.jpg\"/></strong>\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    2021年3月25日，中国工程机械工业协会工程机械租赁分会三届五次理事会召开，会议由中国工程机械工业协会租赁分会副秘书长刘佳主持，中国工程机械工业协会租赁分会副会长兼秘书长李涵兵作《2020年工程机械租赁分会工作总结和2021年工作规划》的报告，庞源租赁、徐工广联租赁、众能联合、柳工美卓，甘肃建投等理事单位代表出席会议，会上就新会员资格认定，行业专家推荐等方面议题进行了讨论并通过。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    2020年中国工程机械工业协会工程机械租赁分会年会在成都的成功召开，为工程机械租赁的行业健康发展指明了方向，继续引领行业走向美好的明天。\n" +
              "</p>\n" +
              "<p style=\"margin-top: 0px; margin-bottom: 0px; padding: 10px 0px; border: none; outline: 0px; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-stretch: inherit; line-height: 28.8px; font-family: &quot;Microsoft YaHei&quot;; vertical-align: baseline; text-size-adjust: none; text-indent: 2em; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <span style=\"margin: 0px; padding: 0px; border: none; outline: 0px; font: inherit; vertical-align: baseline; color: rgb(178, 34, 34);\"><strong>【中国路面机械网 柴喜男原创文章，转载请注明出处及作者】</strong></span>\n" +
              "</p>\n" +
              "<p>\n" +
              "    <br/>\n" +
              "</p>",
        },
        {
          title: '快的打车联合创始人兼技术副总裁闻诚：CTO要有“334”能力',
          time: '2015-02-16',
          brief: '快的打车成立于2012年，发展至今已覆盖全国360个城市。近期，CTO俱乐部采访了快的打车联合创始人兼技术副总裁闻诚，请他分享“快的”的蜕变之路。 闻诚，2007年浙大硕士毕业，随后在一家外企工作5年，当时主要负责移动互联网方面的产品。用他的话来说“尽管人在外企，但心里总想着能做点什么”。那时的他就认定未来移动互联网一定是大趋势，由此有了创业的想法。 快的打车联合创始人兼技术副总裁 闻诚 一路走来，每一步都很精彩！ CTO俱乐部：回头看这一路的努力和经历，有哪些让你最为难忘的人或事？',
          details: "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    快的打车成立于2012年，发展至今已覆盖全国360个城市。近期，CTO俱乐部采访了快的打车联合创始人兼技术副总裁闻诚，请他分享“快的”的蜕变之路。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    闻诚，2007年浙大硕士毕业，随后在一家外企工作5年，当时主要负责移动互联网方面的产品。用他的话来说“尽管人在外企，但心里总想着能做点什么”。那时的他就认定未来移动互联网一定是大趋势，由此有了创业的想法。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); text-align: center; line-height: 26px !important;\">\n" +
              "    <img src=\"http://img.ptcms.csdn.net/article/201502/11/54daf2f333816.jpg\" alt=\"\"/>&nbsp;<br/>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); text-align: center; line-height: 26px !important;\">\n" +
              "    快的打车联合创始人兼技术副总裁 &nbsp;闻诚<br/>\n" +
              "</p>\n" +
              "<h3 style=\"box-sizing: border-box; outline: 0px; margin: 8px 0px 16px; padding: 0px; font-family: &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, SimHei, Arial, SimSun; font-size: 22px; color: rgb(79, 79, 79); line-height: 30px; overflow-wrap: break-word; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><span style=\"box-sizing: border-box; outline: 0px; margin: 0px; padding: 0px; font-weight: normal; overflow-wrap: break-word; color: rgb(227, 108, 9);\">一路走来，每一步都很精彩！</span>\n" +
              "</h3>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">CTO俱乐部：回头看这一路的努力和经历，有哪些让你最为难忘的人或事？</span>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>创业的经历其实每一步都非常难忘。和在公司中打工不同，创业的每个困难都要去克服。因此，创业方向非常重要，所做出的每个决定都有非常大的影响。经过不同的尝试，发现一个最有可能正确的方向，做最正确的决定。这个过程对自己能力以及团队等各层面都有极大的挑战。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    2014年初闹得沸沸扬扬的打车大战，可以说是一场突然爆发的战争。这场战争中，快的和对手在技术上都没有做好充分的准备，在打车大战期间，用过快的打车的同学可能会发现有服务不可用的情况发生，这就是我们所经历的一次非常大的考验。但值得庆幸的是，公司的初始架构非常健康，在未借助外部力量的情况下，仅依靠团队自身力量，最终扛下来这次打车大战。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    同样，2014年又经历了双十二大促，订单量、成交量、使用人数（包括乘客和司机）、交易金额等各种数据都破了新高，面对这样的大场面，我们的技术团队显得非常从容，这也是团队巨大的成长。\n" +
              "</p>\n" +
              "<h3 style=\"box-sizing: border-box; outline: 0px; margin: 8px 0px 16px; padding: 0px; font-family: &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, SimHei, Arial, SimSun; font-size: 22px; color: rgb(79, 79, 79); line-height: 30px; overflow-wrap: break-word; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><span style=\"box-sizing: border-box; outline: 0px; margin: 0px; padding: 0px; font-weight: normal; overflow-wrap: break-word; color: rgb(227, 108, 9);\">CTO的“334”能力</span>\n" +
              "</h3>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">CTO俱乐部：你认为具备哪些素质才能称为是出色的CTO？</span>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>优秀成功的产品有很多。但不管你的想象力有多大，做出什么样的新功能，实现新想法都需要强大的技术团队来支撑。没有这些，所谓的产品体验、产品亮点、产品新功能、运营效率、运营的灵活性等都无从谈起。所以，每个优秀产品的背后，它一定有一支强大的技术团队。而在互联网领域，每个成功产品的背后一定会有非常好的CTO。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    可能对很多CTO来说，技术能力占了绝大部分。但我认为出色的CTO应具备较为综合的素质，应该30%懂产品、30%懂管理、40%懂技术。一名好的CTO只有对产品、管理和技术都有一定的了解，才能比较好地把握整个公司的技术发展方向。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">CTO俱乐部：在带团队过程中，招人最看重哪些方面？</span>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>快的打车目前共有1000多人，技术人员约200人。技术团队按照客户端、测试、服务端以及大数据团队几方面来划分。目标明确、就事论事、直接和开放，没有过多的层级，这些算是我们团队最明显的特点。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    招人时的一些基本要求，可以概括为两个“Yes”和两个“No”。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    两个“Yes”。（1）人是否聪明。也就是指能把事情做好的可能性有多大、潜力有多大。我所说的聪明是个比较均衡的概念，不是说技术特别牛就肯定算聪明，而是指技术好之外，沟通表达等其他各方面相对都比较好；（2）求职的意向和态度。他是否愿意加入一家创业公司，一起去拼搏。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    两个“No”。（1）变动频繁的人。因为这些人方向不明确，或许他的目的是通过频繁跳槽来获取高额工资。（2）特别有个性的人。比如技术特别强或沟通特别好，但性格等其他方面很有个性的人，有可能会协作力比较差。\n" +
              "</p>\n" +
              "<h3 style=\"box-sizing: border-box; outline: 0px; margin: 8px 0px 16px; padding: 0px; font-family: &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, SimHei, Arial, SimSun; font-size: 22px; color: rgb(79, 79, 79); line-height: 30px; overflow-wrap: break-word; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><span style=\"box-sizing: border-box; outline: 0px; overflow-wrap: break-word;\"><span style=\"box-sizing: border-box; outline: 0px; margin: 0px; padding: 0px; overflow-wrap: break-word; color: rgb(227, 108, 9);\">“快的”的进化论</span></span>\n" +
              "</h3>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">CTO俱乐部：在快的打车的产品开发过程中有哪些重要的节点？在改善产品用户体验方面，团队做了哪些尝试和努力？</span>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>&nbsp;2012年4月开始开发快的打车；6月中旬推出第一个版本；8月底正式上线；2013年年底遍布全国200多个城市，而到2014年就已覆盖全国360多个城市。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    第一，提升用户体验。用户体验方面，最关键的一点就是打车的成功率，不同的城市会有不同的特点，比如出租车、司机的数量，城市的发展程度以及出租车司机的习惯和乘客用车的习惯都大不同。所以在产品上，根据不同的城市，会有一些不同的订单及与之匹配的策略，定制个性化的产品策略。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    第二，打车软件竞争非常激烈，发展也很快，所以通过不同的策略、运营工具来提升运营效率，花更少的钱做更多的事以达到相同效果。\n" +
              "</p>\n" +
              "<h3 style=\"box-sizing: border-box; outline: 0px; margin: 8px 0px 16px; padding: 0px; font-family: &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, SimHei, Arial, SimSun; font-size: 22px; color: rgb(79, 79, 79); line-height: 30px; overflow-wrap: break-word; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><span style=\"box-sizing: border-box; outline: 0px; margin: 0px; padding: 0px; font-weight: normal; overflow-wrap: break-word; color: rgb(227, 108, 9);\">CTO俱乐部：面对激烈的市场竞争，快的打车的特点体现在哪些方面？</span>\n" +
              "</h3>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>打车软件从一开始的几十家，到现在发展成双寡头的局面，快的占据市场60%左右的份额，靠的是两点：一是产品体验；二是线上线下团队执行力强。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    新的技术我们用得挺多，尤其是大数据方面，在实时计算分析、数据仓库等方面都用到一些前沿的技术。传统企业做大数据其实都是离线分析，利用离线数据来做一些运算匹配。但打车情况不同，这是非常实时的事情，出租车过5秒种、10秒种，可能就开出几百米了，而用户等10秒钟，可能就没有耐心了会取消定单，所以打车软件在实时性这方面要求非常高。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">CTO俱乐部：下一阶段公司的技术产品重点聚焦在哪部分？</span>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>重点体现在以下几方面。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <br/>\n" +
              "</p>\n" +
              "<ul style=\"list-style-type: none;\" class=\" list-paddingleft-2\">\n" +
              "    <li>\n" +
              "        <p>\n" +
              "            一号专车。\n" +
              "        </p>\n" +
              "    </li>\n" +
              "    <li>\n" +
              "        <p>\n" +
              "            改善用户体验。经过几个月的发展，“快的”的成交量、业务量发展得相当快速。接下来会在用户体验上继续精耕细作。改进用户体验还有很多工作要做，烧钱和拼补贴只是打造品牌的第一步，但这样的模式是不可持续的。\n" +
              "        </p>\n" +
              "    </li>\n" +
              "    <li>\n" +
              "        <p>\n" +
              "            在大数据、算法、策略上体现独到的核心竞争力，和对手拉开差距。\n" +
              "        </p>\n" +
              "    </li>\n" +
              "    <li>\n" +
              "        <p>\n" +
              "            向出行平台方向发展，提升打车成功率以及用户出行成功率。\n" +
              "        </p>\n" +
              "    </li>\n" +
              "</ul>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <br/>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    谁能把效率做得更高，谁就能把这方面运营发挥得最好。用打车软件降低空驶率，从技术上来说我们还有很大的空间做优化，我相信这个改变是非常巨大的。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    谈及给用户带来的价值，其实对司机来讲，通过打车软件可以赚更多的钱；同时也变相地增加了用户量。对用户来讲，提升了出行效率。打车软件带来了诸多的便利性，比如你到路口去拦车，可以把一种不确定性变得相对确定，提升了出行率。\n" +
              "</p>\n" +
              "<h3 style=\"box-sizing: border-box; outline: 0px; margin: 8px 0px 16px; padding: 0px; font-family: &quot;PingFang SC&quot;, &quot;Microsoft YaHei&quot;, SimHei, Arial, SimSun; font-size: 22px; color: rgb(79, 79, 79); line-height: 30px; overflow-wrap: break-word; white-space: normal; background-color: rgb(255, 255, 255);\">\n" +
              "    <a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><a style=\"box-sizing: border-box; outline: none; margin: 0px; padding: 0px; font-weight: normal; cursor: pointer; color: rgb(78, 161, 219); overflow-wrap: break-word;\"></a><span style=\"box-sizing: border-box; outline: 0px; margin: 0px; padding: 0px; font-weight: normal; overflow-wrap: break-word; color: rgb(227, 108, 9);\">未来移动互联网将无所不入</span>\n" +
              "</h3>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">CTO俱乐部：你会用哪些关键词来概况2014年IT互联网发展的特点？</span>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>可以两个字来概括。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    一是“乱”。这个乱包括巨头之间各种层面的竞争，当然这是一种好现象，百花齐放，大家都在这个领域里做各自的努力和探索。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    二是“快”。互联网本身就是“快”行业，但移动互联网要比传统的互联网还要快，而且要快很多。像快的打车这样的公司，两年时间就到千人的规模，在行业内造成这么大的影响力，对用户行为与用户生活的改变其实也超出我们想象。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">CTO俱乐部：你怎么看未来所在行业的发展趋势？</span>\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    <span style=\"box-sizing: border-box; outline: 0px; font-weight: 700; overflow-wrap: break-word;\">闻诚：</span>未来移动互联网对生活的改变会越来越深刻，无所不入，手机可以替代生活中很多事。\n" +
              "</p>\n" +
              "<p style=\"box-sizing: border-box; outline: 0px; margin-top: 0px; margin-bottom: 16px; padding: 0px; color: rgb(77, 77, 77); overflow: auto hidden; overflow-wrap: break-word; font-family: -apple-system, &quot;SF UI Text&quot;, Arial, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei&quot;, &quot;WenQuanYi Micro Hei&quot;, sans-serif, SimHei, SimSun; white-space: normal; background-color: rgb(255, 255, 255); line-height: 26px !important;\">\n" +
              "    移动互联网发展到一定阶段必然会出现一个事情，即使“快的”不去做，也会有别的公司去做，因为当在某个时间点、网络条件、硬件条件、用户思维习惯等都达到一个层面时，有些东西就必然会出现。\n" +
              "</p>\n" +
              "<p>\n" +
              "    <br/>\n" +
              "</p>",
        },
      ]
    }
  },
  watch: {
    $route: {
      handler() {
        this.active = this.$route.params.id;
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    }
  },
  mounted() {
    this.active = this.$route.params.id ? this.$route.params.id : 0;
  }
}
</script>

<style lang="less">
.newsDetail {
  .top {
    padding: 13px 18px;
  }

  .content {
    padding: 0 18px;
    margin-top: 38px;
    text-align: left;

    img {
      width: 100%;
    }

    .tit {
      font-size: 22px;
      font-weight: 600;
      color: #222222;
      line-height: 30px;
      letter-spacing: 1px;
    }

    .time {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      text-align: right;
      margin: 10px auto 20px auto;
    }
  }

  .k {
    width: 100%;
    height: 12px;
    background: #F9F9F9;
  }

  .news-list {
    padding: 13px 18px;

    .items {
      padding: 23px 0;
      border-bottom: 1px solid #D8D8D8;

      .t-title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
        width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .t-detail {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
</style>
