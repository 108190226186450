<template>
  <div class="team">
    <div class="top">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/banner.png" width="100%"/>
      <div class="content">
      </div>
    </div>
    <div class="contain">
      <div class="title-groups">
        <p class="title">核心高管</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <h3 style="text-indent: 0em">王森华 CEO 创始人</h3>
        <p style="text-indent: 0em">北京航空航天大学博士，专注于互联网大数据。资深互联网从业者，多年的国内著名企业管理经验，一直在TOP1的互联网企业担任高级管理者。在商业模式创新、互联网平台架构设计、
          产业互联网创新、互联网公司运营方面有资深的专业经验。曾任职于民生银行、去哪儿网、 滴滴出行、运满满 。</p>
        <!--        <h3 style="text-indent: 0em">邓新 COO 专业运输</h3>
                <p style="text-indent: 0em">高级物流师
                  ，16年大件及工程物流行业经验。2008年创办4A级大件物流公司，年产值过亿。获得1项发明专利，10项实用新型专利，2项软件著作权。两项软著为运输行业的车辆转弯半径、爬坡度以及牵引力的精确验算提供依据，被广泛应用于各类大型物件运输项目中，提高了运输安全系数，实现了经济效益，推动了大件运输行业的技术升级。</p>
                -->
        <h3 style="text-indent: 0em">李梓慧 CMO 首席营销官</h3>
        <p style="text-indent: 0em">南京大学本科
          ，18年销售管理经验，历任富士康商务部经理、苏州奥迪大客户总监（2013年全国奥迪销售冠军）。2015年加入智通三千，联合创始人、董事、任全国招商总经理（副总裁），三年多的时间招商业绩三个多亿，布局线下900多个分公司，年交易额突破50亿。销售、管理领域有丰富的经验。</p>
      </div>
      <div class="title-groups">
        <p class="title">团队照片</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <div class="carousel">
          <div id="jssor_1"
               style="position: relative; margin: 0 auto; top: 0px; left: 0px; width: 800px; height: 710px; overflow: hidden; visibility: hidden; background-color: #ffffff;">
            <!-- Loading Screen -->
            <div data-u="loading" style="position: absolute; top: 0px; left: 0px;">
              <div
                  style="filter: alpha(opacity=70); opacity: 0.7; position: absolute; display: block; top: 0px; left: 0px; width: 100%; height: 100%;"></div>
              <div
                  style="position:absolute;display:block;background:url('https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/loading.gif') no-repeat center center;top:0px;left:0px;width:100%;height:100%;"></div>
            </div>
            <div data-u="slides"
                 style="cursor: default; position: relative; top: 0px; left: 0px; width: 800px; height: 538px; overflow: hidden;">
              <div data-p="144.50" style="display: none;" class="img-g" v-for="(item,index) in imgList" :key="index">
                <div data-u="image">
                  <img :src="item" width="100%" height="100%"/>
                </div>
                <div data-u="thumb">
                  <img :src="item" width="100%" height="100%"/>
                </div>
              </div>
            </div>
            <!-- Thumbnail Navigator -->
            <div data-u="thumbnavigator" class="jssort01"
                 style="position:absolute;left:0px;bottom:0px;width:800px;height:175px;" data-autocenter="1">
              <!-- Thumbnail Item Skin Begin -->
              <div data-u="slides" style="cursor: default;">
                <div data-u="prototype" class="p">
                  <div class="w">
                    <div data-u="thumbnailtemplate" class="t"></div>
                  </div>
                  <div class="c"></div>
                </div>
              </div>
              <!-- Thumbnail Item Skin End -->
            </div>
            <!-- Arrow Navigator -->
            <span data-u="arrowleft" class="jssora05l" style="top:260px;left:8px;width:40px;height:40px;"></span>
            <span data-u="arrowright" class="jssora05r" style="top:260px;right:8px;width:40px;height:40px;"></span>
          </div>
        </div>
      </div>
      <div class="title-groups">
        <p class="title">企业新闻</p>
        <div class="line"></div>
      </div>
      <el-row class="content" :gutter="10">
        <el-col :span="12" v-for="(item,index) in newsList" :key="index">
          <div @click="openClick(item)">
            <el-row class="news">
              <el-col :span="7"><img style="display: block" :src="item.img" width="100%" height="100%"/></el-col>
              <el-col :span="17" class="news-item">
                <p class="tit">{{ item.title }}</p>
                <p class="detail">{{ item.detail }}</p>
                <p class="time">{{ item.time }}</p>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import '../utils/jssor.slider.mini.js'

export default {
  name: "team",
  data() {
    return {
      imgList: [
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l1.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l2.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l3.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l4.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l5.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l6.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l7.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l8.jpg',
      ],
      newsList: [
        {
          url: '/newsDetail/0',
          img: 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/new-1.png',
          title: '工程机械租赁行业大咖论道逆境破局，加速重构市场新格局',
          detail: '2021年3月25-26日，以“逆境破局，加速重构！”为主题的2020年中国工程机械工业协会工程机械租赁分会年会在四川成都市隆重举行。本次会议是由中国工程机械工业协会指导，中国工程机械工业协会工程机械租赁分会主办。会议深度剖析当前形势下工程机械租赁面临的主要问题，共同探讨如何推进行业高质量发展，助力制造企业、租赁企业把握机遇，展望未来。',
          time: '2021-03-26',
          type: 0
        },
        /*{
          url: '/newsDetail/1',
          img: 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/new-2.png',
          title: '快的打车联合创始人兼技术副总裁闻诚：CTO要有“334”能力',
          detail: '快的打车成立于2012年，发展至今已覆盖全国360个城市。近期，CTO俱乐部采访了快的打车联合创始人兼技术副总裁闻诚，请他分享“快的”的蜕变之路。 闻诚，2007年浙大硕士毕业，随后在一家外企工作5年，当时主要负责移动互联网方面的产品。用他的话来说“尽管人在外企，但心里总想着能做点什么”。那时的他就认定未来移动互联网一定是大趋势，由此有了创业的想法。 快的打车联合创始人兼技术副总裁 闻诚 一路走来，每一步都很精彩！ CTO俱乐部：回头看这一路的努力和经历，有哪些让你最为难忘的人或事？',
          time: '2015-02-16'
        },*/
        {
          url: 'https://www.toutiao.com/article/7153809927343981067/?&source=m_redirect&wid=1667012851175',
          img: 'http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/date/%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20221029115326.png',
          title: '顺风大运完成数千万元A轮融资',
          detail: '近日，顺风大运宣布完成数千万人民币A轮融资，本轮由创业工场领投此轮融资将用于扩大业务规模，加大研发投入及算法的持续迭代。',
          time: '2022-10-13',
          type: 1
        },
        {
          url: 'http://www.jiaotongwang.com.cn/index/wuliu/2024/0420/512468.html',
          img: 'http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/dateundefined/171375506875620240422-110422.jpg',
          title: '中国水利电力物资流通协会 2024 年度工作会议在长沙圆满举行',
          detail: '中国交通在线  (沈君波)  4 月 18 日上午，中国水利电力物资流通协会 2024 年度工作会议于湖南长沙成功举办。此次会议全面总结了 2023 年的工作，并对 2024 年的工作进行了详细部署。会议聚焦工作重点，深入分析了行业发展现状，通过加强交流与集思广益，凝聚起了团结奋进、积极向上的强大精神力量。近 200 名来自全国各地的会员代表参加了此次会议，交通运输部公路局路网管理处以及湖南省交通厅政务服务处的相关领导和嘉宾也应邀出席。',
          time: '2024-04-20',
          type: 1
        },
        {
          url: 'https://mp.weixin.qq.com/s/GnEcVKrlxUBbG9BFlsB1dQ',
          img: 'http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/date/balanceSettlement/171539447571220240511-102745.jpg',
          title: '潘智勇会长一行赴智运通调研',
          detail: '2024年5月7日，潘智勇会长一行到智运通（北京）科技有限责任公司进行调研。智运通公司王森华董事长就公司成立的背景、组织架构、业务板块以及未来愿景向潘会长做了介绍。',
          time: '2024-05-10',
          type: 1
        },
      ]
    }
  },
  mounted() {

    jQuery(document).ready(function ($) {

      var jssor_1_SlideshowTransitions = [
        {
          $Duration: 1200,
          x: 0.3,
          $During: {$Left: [0.3, 0.7]},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: -0.3,
          $SlideOut: true,
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: -0.3,
          $During: {$Left: [0.3, 0.7]},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $SlideOut: true,
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $During: {$Top: [0.3, 0.7]},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: -0.3,
          $SlideOut: true,
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: -0.3,
          $During: {$Top: [0.3, 0.7]},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $SlideOut: true,
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $Cols: 2,
          $During: {$Left: [0.3, 0.7]},
          $ChessMode: {$Column: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $Cols: 2,
          $SlideOut: true,
          $ChessMode: {$Column: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $Rows: 2,
          $During: {$Top: [0.3, 0.7]},
          $ChessMode: {$Row: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $Rows: 2,
          $SlideOut: true,
          $ChessMode: {$Row: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: 0.3,
          $Cols: 2,
          $During: {$Top: [0.3, 0.7]},
          $ChessMode: {$Column: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          y: -0.3,
          $Cols: 2,
          $SlideOut: true,
          $ChessMode: {$Column: 12},
          $Easing: {$Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          $Rows: 2,
          $During: {$Left: [0.3, 0.7]},
          $ChessMode: {$Row: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: -0.3,
          $Rows: 2,
          $SlideOut: true,
          $ChessMode: {$Row: 3},
          $Easing: {$Left: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          y: 0.3,
          $Cols: 2,
          $Rows: 2,
          $During: {$Left: [0.3, 0.7], $Top: [0.3, 0.7]},
          $ChessMode: {$Column: 3, $Row: 12},
          $Easing: {$Left: $Jease$.$InCubic, $Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          x: 0.3,
          y: 0.3,
          $Cols: 2,
          $Rows: 2,
          $During: {$Left: [0.3, 0.7], $Top: [0.3, 0.7]},
          $SlideOut: true,
          $ChessMode: {$Column: 3, $Row: 12},
          $Easing: {$Left: $Jease$.$InCubic, $Top: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 3,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 3,
          $SlideOut: true,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$OutCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 12,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$InCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        },
        {
          $Duration: 1200,
          $Delay: 20,
          $Clip: 12,
          $SlideOut: true,
          $Assembly: 260,
          $Easing: {$Clip: $Jease$.$OutCubic, $Opacity: $Jease$.$Linear},
          $Opacity: 2
        }
      ];

      var jssor_1_options = {
        $AutoPlay: true,
        $SlideshowOptions: {
          $Class: $JssorSlideshowRunner$,
          $Transitions: jssor_1_SlideshowTransitions,
          $TransitionsOrder: 1
        },
        $ArrowNavigatorOptions: {
          $Class: $JssorArrowNavigator$
        },
        $ThumbnailNavigatorOptions: {
          $Class: $JssorThumbnailNavigator$,
          $Cols: 5,
          $SpacingX: 8,
          $SpacingY: 8,
          $Align: 360
        }
      };

      var jssor_1_slider = new $JssorSlider$("jssor_1", jssor_1_options);

      //responsive code begin
      //you can remove responsive code if you don't want the slider scales while window resizing
      function ScaleSlider() {
        var refSize = jssor_1_slider.$Elmt.parentNode.clientWidth;
        if (refSize) {
          refSize = Math.min(refSize, 900);
          jssor_1_slider.$ScaleWidth(refSize);
        } else {
          window.setTimeout(ScaleSlider, 30);
        }
      }

      ScaleSlider();
      $(window).bind("load", ScaleSlider);
      $(window).bind("resize", ScaleSlider);
      $(window).bind("orientationchange", ScaleSlider);
      //responsive code end
    });
  },
  methods: {
    openClick(e) {
      if (e.type === 0) {
        this.$router.push(e.url);
      } else {
        window.open(e.url, '_blank');
      }
    }
  }
}
</script>

<style lang="less">
.team {
  margin-top: 0.90rem;

  .top {

  }

  .contain {
    .title-groups {
      margin-top: 50px;
      margin-bottom: 70px;
      text-align: center;

      .title {
        font-size: 30px;
        font-weight: 600;
        color: #222222;
        line-height: 42px;
      }

      .line {
        width: 34px;
        height: 3px;
        background: #0274F6;
        margin: 5px auto 0 auto;
      }
    }

    .content {
      font-size: 14px;
      text-align: left;
      padding-bottom: 20px;

      .carousel {
        .w {

          position: relative;

          .title {
            position: absolute;
            bottom: 1px;
            left: 0;
            right: 0;
            z-index: 999;
            color: #ffffff;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
          }
        }

        .img-g {
          position: relative;

          .title {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            z-index: 999;
            color: #ffffff;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal;
            text-overflow: ellipsis;
          }
        }
      }

      .jssora05l, .jssora05r {
        display: block;
        position: absolute;
        /* size of arrow element */
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: url('https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/profession/a17.png') no-repeat;
        overflow: hidden;
      }

      .jssora05l {
        background-position: -10px -40px;
      }

      .jssora05r {
        background-position: -70px -40px;
      }

      .jssora05l:hover {
        background-position: -130px -40px;
      }

      .jssora05r:hover {
        background-position: -190px -40px;
      }

      .jssora05l.jssora05ldn {
        background-position: -250px -40px;
      }

      .jssora05r.jssora05rdn {
        background-position: -310px -40px;
      }

      /* jssor slider thumbnail navigator skin 01 css */
      /*
      .jssort01 .p            (normal)
      .jssort01 .p:hover      (normal mouseover)
      .jssort01 .p.pav        (active)
      .jssort01 .p.pdn        (mousedown)
      */

      .jssort01 .p {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 140px;
      }

      .jssort01 .t {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }

      .jssort01 .w {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .jssort01 .c {
        position: absolute;
        top: 0;
        left: 0;
        width: 198px;
        height: 138px;
        border: 2px solid #FFFFFF;
        box-sizing: content-box;
        //background: url('../assets/profession/t01.png') -800px -800px no-repeat;
        _background: none;
      }

      .jssort01 .pav .c {
        top: 2px;
        left: 2px;
        width: 198px;
        height: 138px;
        border: 1px solid #000;
        //background-position: 50% 50%;
      }

      .jssort01 .p:hover .c {
        top: 0;
        left: 0;
        width: 198px;
        height: 138px;
        border: #fff 1px solid;
        background-position: 50% 50%;
      }

      .jssort01 .p.pdn .c {
        background-position: 50% 50%;
        width: 198px;
        height: 138px;
        border: #000 2px solid;
      }

      * html .jssort01 .c, * html .jssort01 .pdn .c, * html .jssort01 .pav .c {
        /* ie quirks mode adjust */
        width: 198px;
        height: 138px;
      }
    }

    .content {
      font-size: 14px;
      text-align: left;
      padding-bottom: 20px;

      .news {
        background: #F7F7F7;
        margin-bottom: 10px;

        .news-item {
          cursor: pointer;
          padding: 2% 17px 0 26px;
          text-align: left;

          .tit {
            font-size: 20px;
            font-weight: 600;
            color: #222222;
            line-height: 37px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .detail {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            line-height: 22px;
            height: 4em;
          }

          .time {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
