<template>
  <div class="header-m">
    <el-row>
      <el-col :span="20"><img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/other/logo.png"
                              width="40%"/></el-col>
      <el-col :span="4">
        <img v-if="show" src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/other/close.png"
             width="35%" @click="open"/>
        <img v-else src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/other/menu.png" width="50%"
             @click="open"/>
      </el-col>
    </el-row>
    <el-collapse-transition>
      <div v-show="show" class="trans-menus">
        <div class="scroll" :style="'height:' + transitionHeight">
          <div class="transition-box color-1" @click="menuClick('/m/home')">
            <p class="title">首页</p>
            <p class="en-title">HOME PAGE</p>
          </div>
          <div class="transition-box color-2" @click="menuClick('/m/profession')">
            <p class="title">专业</p>
            <p class="en-title">SOLUTION</p>
          </div>
          <div class="transition-box color-3" @click="menuClick('/m/solution')">
            <p class="title">解决方案</p>
            <p class="en-title">MAJOR</p>
          </div>
          <div class="transition-box color-4" @click="menuClick('/m/team')">
            <p class="title">菁英团队</p>
            <p class="en-title">ELITE TEAM</p>
          </div>
          <div class="transition-box color-5" @click="menuClick('/m/about')">
            <p class="title">联系我们</p>
            <p class="en-title">CONTACT US</p>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {
      show: false,
      clientHeight: document.documentElement.clientHeight,
      transitionHeight: (document.documentElement.clientHeight - 60) + 'px'
    }
  },
  watch: {
    clientHeight(newValue, oldValue) {
      if (newValue) {
        this.transitionHeight = (newValue - 60) + 'px'
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.clientHeight = document.documentElement.clientHeight;
    }
  },
  methods: {
    menuClick(e) {
      this.show = false;
      $(document.body).css('overflow', this.show ? 'hidden' : 'scroll')
      this.$router.push(e);
    },
    open() {
      this.show = !this.show;
      $(document.body).css('overflow', this.show ? 'hidden' : 'scroll')
    },
    moveHandle() {
      return;
    },
  }
}
</script>

<style lang="less">
.header-m {
  width: 100%;
  z-index: 999;

  .el-row {
    padding: 0 18px;
    line-height: normal;
    height: 60px;

    .el-col-20 {
      text-align: left;
      margin-top: 15px;
    }

    .el-col-4 {
      margin-top: 23px;
      text-align: right;
    }
  }

  .trans-menus {
    z-index: 999;

    &.scroll {
      overflow-y: scroll;
    }

    .transition-box {
      z-index: 999;
      height: 20%;
      //padding-top: clac(25% - 56px);
      line-height: 25%;
      padding: 10% 52px;
      text-align: left;

      &.color-1 {
        background: #4D9DFB;
      }

      &.color-2 {
        background: rgba(52, 144, 251, 1);
      }

      &.color-3 {
        background: rgba(30, 130, 245, 1);
      }

      &.color-4 {
        background: rgba(2, 116, 246, 1);
      }

      &.color-5 {
        background: rgba(3, 102, 222, 1);
      }

      .title {
        font-size: 28px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
        letter-spacing: 2px;
      }

      .en-title {
        font-size: 20px;
        font-weight: 400;
        color: #D9D9D9;
        line-height: 28px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
