<template>
  <div class="team-m">
    <div class="tm-1">
      <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/team/banner.png" width="100%"/>
    </div>
    <div class="tm-2">
      <div class="title-g">
        <p class="title">核心高管</p>
        <div class="line"></div>
        <h3>王森华 CEO 创始人</h3>
        <p content="detail">北京航空航天大学博士，专注于互联网大数据。资深互联网从业者，多年的国内著名企业管理经验，一直在TOP1的互联网企业担任高级管理者。在商业模式创新、互联网平台架构设计、
          产业互联网创新、互联网公司运营方面有资深的专业经验。曾任职于民生银行、去哪儿网、 滴滴出行、运满满。</p>
        <!--        <h3>邓新 COO 专业运输</h3>
                <p content="detail">高级物流师
                  ，16年大件及工程物流行业经验。2008年创办4A级大件物流公司，年产值过亿。获得1项发明专利，10项实用新型专利，2项软件著作权。两项软著为运输行业的车辆转弯半径、爬坡度以及牵引力的精确验算提供依据，被广泛应用于各类大型物件运输项目中，提高了运输安全系数，实现了经济效益，推动了大件运输行业的技术升级。</p>
                -->
        <h3>李梓慧 CMO 首席营销官</h3>
        <p content="detail">南京大学本科
          ，18年销售管理经验，历任富士康商务部经理、苏州奥迪大客户总监（2013年全国奥迪销售冠军）。2015年加入智通三千，联合创始人、董事、任全国招商总经理（副总裁），三年多的时间招商业绩三个多亿，布局线下900多个分公司，年交易额突破50亿。销售、管理领域有丰富的经验。</p>
      </div>
    </div>
    <div class="tm-3">
      <div class="title-g">
        <p class="title">团队照片</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <el-carousel :interval="4000" type="card" arrow="never" height="140px" ref="pro3">
          <el-carousel-item v-for="item in imgList" :key="item">
            <img :src="item" width="100%"/>
          </el-carousel-item>
        </el-carousel>
        <el-row :gutter="12">
          <el-col :span="12">
            <i class="el-icon-arrow-left icon-diy" @click="arrowClick"/>
          </el-col>
          <el-col :span="12">
            <i class="el-icon-arrow-right icon-diy" @click="arrowClick('right')"/>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="tm-4">
      <div class="title-g">
        <p class="title">企业新闻</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <el-row class="news" v-for="(item,index) in newsList" :key="index">
          <el-col :span="8"><img :src="item.img" style="display: block" width="100%" height="100%"></el-col>
          <el-col :span="16">
            <div class="right-con" @click="openClick(item)">
              <p class="title">{{ item.title }}</p>
              <p class="detail">{{ item.detail }}</p>
              <p class="time">{{ item.time }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "teamM",
  data() {
    return {
      imgList: [
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l1.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l2.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l3.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l4.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l5.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l6.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l7.jpg',
        'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/l8.jpg',
      ],
      newsList: [
        {
          url: '/m/newsDetail/0',
          img: 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/new-1.png',
          title: '工程机械租赁行业大咖论道逆境破局，加速重构市场新格局',
          detail: '2021年3月25-26日，以“逆境破局，加速重构！”为主题的2020年中国工程机械工业协会工程机械租赁分会年会在四川成都市隆重举行。本次会议是由中国工程机械工业协会指导，中国工程机械工业协会工程机械租赁分会主办。会议深度剖析当前形势下工程机械租赁面临的主要问题，共同探讨如何推进行业高质量发展，助力制造企业、租赁企业把握机遇，展望未来。',
          time: '2021-03-26',
          type: 0,
        },
        /*  {
            url: '/m/newsDetail/1',
            img: 'https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/team/new-2.png',
            title: '快的打车联合创始人兼技术副总裁闻诚：CTO要有“334”能力',
            detail: '快的打车成立于2012年，发展至今已覆盖全国360个城市。近期，CTO俱乐部采访了快的打车联合创始人兼技术副总裁闻诚，请他分享“快的”的蜕变之路。 闻诚，2007年浙大硕士毕业，随后在一家外企工作5年，当时主要负责移动互联网方面的产品。用他的话来说“尽管人在外企，但心里总想着能做点什么”。那时的他就认定未来移动互联网一定是大趋势，由此有了创业的想法。 快的打车联合创始人兼技术副总裁 闻诚 一路走来，每一步都很精彩！ CTO俱乐部：回头看这一路的努力和经历，有哪些让你最为难忘的人或事？',
            time: '2015-02-16',
            type: 0,
          },*/
        {
          url: 'https://www.toutiao.com/article/7153809927343981067/?&source=m_redirect&wid=1667012851175',
          img: 'http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/date/%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20221029115326.png',
          title: '顺风大运完成数千万元A轮融资',
          detail: '近日，顺风大运宣布完成数千万人民币A轮融资，本轮由创业工场领投此轮融资将用于扩大业务规模，加大研发投入及算法的持续迭代。',
          time: '2022-10-13',
          type: 1
        },
        {
          url: 'http://www.jiaotongwang.com.cn/index/wuliu/2024/0420/512468.html',
          img: 'http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/dateundefined/171375506875620240422-110422.jpg',
          title: '中国水利电力物资流通协会 2024 年度工作会议在长沙圆满举行',
          detail: '中国交通在线  (沈君波)  4 月 18 日上午，中国水利电力物资流通协会 2024 年度工作会议于湖南长沙成功举办。此次会议全面总结了 2023 年的工作，并对 2024 年的工作进行了详细部署。会议聚焦工作重点，深入分析了行业发展现状，通过加强交流与集思广益，凝聚起了团结奋进、积极向上的强大精神力量。近 200 名来自全国各地的会员代表参加了此次会议，交通运输部公路局路网管理处以及湖南省交通厅政务服务处的相关领导和嘉宾也应邀出席。',
          time: '2024-04-20',
          type: 1
        },
        {
          url: 'https://mp.weixin.qq.com/s/GnEcVKrlxUBbG9BFlsB1dQ',
          img: 'http://admin-plat-1302681310.cos.ap-beijing.myqcloud.com/test/date/balanceSettlement/171539447571220240511-102745.jpg',
          title: '潘智勇会长一行赴智运通调研',
          detail: '2024年5月7日，潘智勇会长一行到智运通（北京）科技有限责任公司进行调研。智运通公司王森华董事长就公司成立的背景、组织架构、业务板块以及未来愿景向潘会长做了介绍。',
          time: '2024-05-10',
          type: 1
        },
      ]
    }
  },
  methods: {
    openClick(e) {
      if (e.type === 0) {
        this.$router.push(e.url);
      } else {
        window.open(e.url, '_blank');
      }
    },
    arrowClick(val) {
      console.log('1212');
      if (val === 'right') {
        this.$refs.pro3.next();
      } else {
        this.$refs.pro3.prev();
      }
    },
  }
}
</script>

<style lang="less">
.team-m {

  .title-g {
    margin-top: 55px;
    text-align: center;
    margin-bottom: 28px;

    .title {
      font-size: 22px;
      font-weight: 800;
      color: #222222;
      line-height: 30px;
      letter-spacing: 1px;
    }

    .line {
      width: 18px;
      height: 1px;
      background: #0274F6;
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 8px;
    }

    .detail {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: #222222;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }

  .tm-2 {
    padding: 0 18px;
  }

  .tm-3 {
    padding: 0 18px 30px 18px;

    .el-carousel__indicators--outside {
      display: none;
    }
  }

  .tm-4 {
    padding: 0 18px;

    .content {

      margin-bottom: 30px;

      .news {
        margin-top: 7px;
        text-align: left;
        background: #F7F7F7;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .right-con {
          padding: 10% 15px 0 15px;
          background: #F7F7F7;

          .title {
            height: 21px;
            font-size: 15px;
            font-weight: bold;
            color: #222222;
            line-height: 21px;
            width: 75%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .detail {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 13px;
            font-weight: 400;
            color: #222222;
            line-height: 18px;
          }

          .time {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }
        }
      }
    }
  }
}
</style>
