<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: "App",
  watch: {
    '$route': function (to, from) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  },
  created() {
    /*//跳转到首页
    if(window.location.host!='www.sfdy.com'){
      window.location.href='https://www.sfdy.com/home'
      return true
    }*/
    // 定义最大的 fontSize
    const MAX_FONT_SIZE = 42
    // 监听 HTML 文档被解析完成的事件
    document.addEventListener('DOMContentLoaded', () => {
      // 获取html标签
      const html = document.querySelector('html')
      let fontSize = window.innerWidth / 19.20;
      if (this._isMobile()) {
        fontSize = 12
      }
      // 获取到的 fontSize 标准不允许超过我们定义的最大值
      // fontSize = fontSize > MAX_FONT_SIZE ? MAX_FONT_SIZE : fontSize
      // 定义根元素 大小
      html.style.fontSize = fontSize + 'px';
    })
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("yi移动端");
      if (this.$route.path.indexOf('/m') == -1) {
        this.$router.push('/m' + this.$route.path);
      }
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      console.log(this.$route.path.indexOf('/m'));
      if (this.$route.path.indexOf('/m') == 0) {
        this.$router.push(this.$route.path.substr(2));
      }
    }
  },
  methods: {
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  }
};
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
